import * as moment from 'moment-timezone'
import { Time } from './styled'

export const openOrClosed = (hours: any) => {
  const date = new Date()
  let closingHour = ''
  //    if (business?.googleData?.result?.opening_hours) {
  hours.map((item: any) => {
    if (item?.close?.day === date.getDay()) {
      if (item.open.time === item.close.time) {
        return null
      }
      closingHour = item.close.time
    }
    return null
  })
  if (!closingHour) {
    return false
  }
  return (
    <Time>{` - Closes at ${new Date(
      '1970-01-01T' + closingHour.substring(0, 2) + ':' + closingHour.substring(2) + 'Z'
    ).toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}`}</Time>
  )
  //  }
}

export const openOrClosedYelp = (hours: any) => {
  const date = new Date()
  let closingHour = ''
  // eslint-disable-next-line array-callback-return
  hours.map((item: any) => {
    if (date.getDay() === 0) {
      if (date.getDay() + 6 === item?.day) {
        if (item.start === item.end) {
          return null
        }
        closingHour = item.end
      }
    }
    if (date.getDay() - 1 === item?.day) {
      if (item.start === item.end) {
        return null
      }
      closingHour = item?.end
    }
  })
  if (!closingHour) {
    return false
  }
  return (
    <Time>{` - Closes at ${new Date(
      '1970-01-01T' + closingHour.substring(0, 2) + ':' + closingHour.substring(2) + 'Z'
    ).toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}`}</Time>
  )
}

export const daysAndTime = (dayAndTime: any) => {
  const hours: any = []
  const time: any = []
  if (dayAndTime) {
    // eslint-disable-next-line array-callback-return
    dayAndTime.map((data: any) => {
      if (
        // eslint-disable-next-line array-callback-return
        time.some((item: any, index: number) => {
          if (item?.close?.day === data?.close?.day) {
            time[index].close.time = data?.close?.time
            return true
          }
        })
      ) {
      } else {
        time.push(data)
      }
    })
    // eslint-disable-next-line array-callback-return
    time.map((item: any) => {
      hours.push(item?.close?.day)
    })
    for (let i = 0; i < 7; i++) {
      if (!hours.includes(i)) {
        time.push({
          close: {
            day: i,
            flag: true,
          },
        })
      }
    }
  }
  return time.sort((a: any, b: any) => a?.close?.day - b?.close?.day)
}

export const daysAndTimeYelp = (dayAndTime: any) => {
  const hoursYelp: any = []
  const timeYelp: any = []
  if (dayAndTime) {
    // eslint-disable-next-line array-callback-return
    dayAndTime.map((data: any) => {
      if (
        // eslint-disable-next-line array-callback-return
        timeYelp.some((item: any, index: number) => {
          if (item?.day === data?.day) {
            timeYelp[index].end = data?.end
            return true
          }
        })
      ) {
      } else {
        timeYelp.push(data)
      }
    })
    // eslint-disable-next-line array-callback-return
    timeYelp.map((item: any) => {
      hoursYelp.push(item?.day)
    })
    for (let i = 0; i < 7; i++) {
      if (!hoursYelp.includes(i)) {
        timeYelp.push({
          day: i,
          flag: true,
        })
      }
    }
  }
  return timeYelp.sort((a: any, b: any) => a.day - b.day)
}

export const isOpen = (hours: any, timezone: string) => {
  const date = new Date()
  const localTime = moment.tz(date.getTime(), timezone)
  let open = false
  /* const cityLookup = timeZones.findFromCityStateProvince(`${city} ${state}`)
  const cityFiltered = cityLookup.filter((item: any) => item.iso3 === 'USA') */
  hours.map((item: any) => {
    if (item?.open?.day === 0 && item.open?.time === '0000') {
      open = true
    } else if (item?.close?.day === date.getDay()) {
      if (timezone) {
        const storeOpenTime = moment.tz(
          item.open.time.substring(0, 2) + ':' + item.open.time.substring(2),
          'h:mmA',
          timezone
        )

        const storeCloseTime = moment.tz(
          item.close.time.substring(0, 2) + ':' + item.close.time.substring(2),
          'h:mmA',
          timezone
        )
        if (storeOpenTime.isSame(storeCloseTime) || parseInt(item.open.time) > parseInt(item.close.time)) {
          open = true
        } else {
          if (!open) {
            open = localTime.isBetween(storeOpenTime, storeCloseTime)
          }
        }
      }
    }
    return null
  })
  return open
}

export const isOpenYelp = (hours: any, timezone: string) => {
  const date = new Date()
  const localTime = moment.tz(date.getTime(), timezone)
  let open = false
  /* const cityLookup = timeZones.findFromCityStateProvince(`${city} ${state}`)
  const cityFiltered = cityLookup.filter((item: any) => item.iso3 === 'USA') */

  hours.map((item: any) => {
    if (date.getDay() === 0) {
      if (date.getDay() + 6 === item?.day) {
        const storeOpenTime = moment.tz(item.start.substring(0, 2) + ':' + item.start.substring(2), 'h:mmA', timezone)

        const storeCloseTime = moment.tz(item.end.substring(0, 2) + ':' + item.end.substring(2), 'h:mmA', timezone)
        if (storeOpenTime.isSame(storeCloseTime) || parseInt(item.start) > parseInt(item.end)) {
          open = true
        } else {
          if (!open) {
            open = localTime.isBetween(storeOpenTime, storeCloseTime)
          }
        }
      }
    }
    if (date.getDay() - 1 === item?.day) {
      const storeOpenTime = moment.tz(item.start.substring(0, 2) + ':' + item.start.substring(2), 'h:mmA', timezone)

      const storeCloseTime = moment.tz(item.end.substring(0, 2) + ':' + item.end.substring(2), 'h:mmA', timezone)

      if (storeOpenTime.isSame(storeCloseTime) || parseInt(item.start) > parseInt(item.end)) {
        open = true
      } else {
        if (!open) {
          open = localTime.isBetween(storeOpenTime, storeCloseTime)
        }
      }
    }
    return null
  })
  return open
}
