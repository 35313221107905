import { staticSelector } from 'ducks/static'
import { useQueryRecovery } from 'hooks/useQueryRecovery'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { BussinesCardDetailComponent } from 'Views/Components/BussinesCardDetailComponent'
import { Header } from 'Views/Components/Header/index.'
import { LoaderPage } from 'Views/Components/LoaderPage'
import { SearchSearchBar } from 'Views/Components/SearchSearchBar'
import { Container } from './styled'

export const BusinessDetail: FC = () => {
  const state = useSelector(staticSelector)

  return (
    <>
      {state?.strapi?.directoryHome ? (
        <Container>
          <BussinesCardDetailComponent />
        </Container>
      ) : (
        <LoaderPage />
      )}
    </>
  )
}
