import { staticSelector } from 'ducks/static'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { resetAutoComplete } from 'ducks/locationApi'
import { useQueryRecovery } from 'hooks/useQueryRecovery'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { businessApiSelector } from 'ducks/businessApi'
import { SearchBarComponent } from '../SearchComponentBar'
import greenBizIcon from '../../../assets/GreenbizIcon.png'
import iconPrueba from '../../../assets/iconPrueba.png'
import {
  Container,
  Title,
  InnerContainer,
  Categorys,
  InnerCategory,
  CategoryTitle,
  CategoryIcon,
  CategoryButton,
  CategoryContainer,
  SearchContainer,
} from './styled'

const DEFAULT_ICON = 'https://gbn-national-site-api-bucket.s3.us-east-2.amazonaws.com/Category_Market_0120d9cd29.png'

export const Banner: FC = () => {
  const state = useSelector(staticSelector)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetAutoComplete())
  }, [dispatch])

  const { handleQueryChange } = useQueryRecovery()

  const banner = state.strapi.directoryHome.DirectoryBanner.Banner[0].url

  const handleClick = (v: string) => () => {
    history.push(`/search?category=${v}`)
  }

  const business = useSelector(businessApiSelector)

  return (
    <Container
      style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        display: 'flex',
        position: 'relative',
        height: '100%',
      }}
    >
      <Helmet>
        <link rel="icon" href={greenBizIcon} />
        <title>Greenbiz Tracker</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Greenbiz Tracker" />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:description" content="A Recognized Green Business" />
        <meta property="og:image" content={iconPrueba} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Greenbiz Tracker" />
        <meta name="twitter:description" content="A Recognized Green Business" />
        <meta name="twitter:image" content={iconPrueba} />
      </Helmet>
      <InnerContainer>
        <SearchContainer>
          <Title>{state.strapi.directoryHome.DirectoryBanner.InsideBanner.Title}</Title>

          <SearchBarComponent disableSagas onQueryChange={handleQueryChange} />
          {!business?.loading ? null : (
            <div style={{ marginTop: '10px', color: 'yellow' }}>
              Loading all the business, wait a moment and try again please.
            </div>
          )}
        </SearchContainer>
      </InnerContainer>
      <Categorys>
        <InnerCategory>
          {state.strapi.categories
            ?.filter(({ showHome }: any) => showHome)
            ?.map(({ id: itemID, name, icon = {}, slug }: any) => {
              return (
                <CategoryContainer key={`category-banner-item-{${itemID}}`}>
                  <CategoryButton onClick={handleClick(slug)}>
                    <div
                      style={{
                        flexDirection: 'column',
                      }}
                    >
                      <CategoryIcon>
                        <img src={icon?.url || DEFAULT_ICON} alt="icon" style={{ width: '20px' }} />
                      </CategoryIcon>
                      <CategoryTitle>{name}</CategoryTitle>
                    </div>
                  </CategoryButton>
                </CategoryContainer>
              )
            })}
        </InnerCategory>
      </Categorys>
    </Container>
  )
}
