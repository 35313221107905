import { FC, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, styled, useMediaQuery } from '@mui/material'
import { MultipleCheckbox } from '../MultipleCheckBox'
import { RadioCheckBox } from '../RadioCheckBox'
import arrowDown from '../../../assets/dropArrow.png'
import * as S from './styled'

interface Field {
  label: string
  name: string
  type: string
  value?: any
  options: any[]
  uniqueKey?: string
  labelKey?: string
}

interface Props {
  title: string
  mobileMode: boolean
  fields: Field[]
  values: any
  onChangeByPropName: (name: string, value: any) => void
}

const FieldDictionary: any = {
  RadioCheckBox,
  MultipleCheckbox,
}

export const FieldSwitcher: FC<Omit<Field, 'label'> & Pick<Props, 'onChangeByPropName'>> = ({
  type,
  onChangeByPropName,
  ...rest
}) => {
  const Component = useMemo(() => FieldDictionary[type], [type])
  const handleChange = (v: any) => {
    onChangeByPropName(rest.name, v)
  }
  return <Component {...rest} onChange={handleChange} />
}

export const Filter: FC<Props> = ({ fields, values, onChangeByPropName }) => {
  const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '.MuiAccordionDetails-root': {},
      '.MuiAccordionSummary-root': {
        borderBottom: '1px solid rgba(236, 222, 187, 1)',
      },
      '&.MuiAccordion-root:before': {
        height: '0px',
        background: 'transparent',
      },
    }
  })
  const [openPanels, setOpenPanels] = useState(fields.map(() => true))

  const filtersToShow = fields.filter((_, j) => j !== 1)

  const isMobile = useMediaQuery('(max-width:1028px)')

  return (
    <>
      <S.FilterSection>
        <S.Title style={{ borderBottom: '0px !important' }}>Filters</S.Title>
        {isMobile ? (
          <>
            <S.Column>
              <S.CategoryTitle>{fields[1].label}</S.CategoryTitle>
              <FieldSwitcher
                type={fields[1].type}
                options={fields[1].options}
                value={values[fields[1].name]}
                name={fields[1].name}
                onChangeByPropName={onChangeByPropName}
                labelKey={fields[1].labelKey}
                uniqueKey={fields[1].uniqueKey}
              />
            </S.Column>
            <S.Column>
              <S.CategoryTitle>{fields[2].label}</S.CategoryTitle>
              <FieldSwitcher
                type={fields[2].type}
                options={fields[2].options}
                value={values[fields[2].name]}
                name={fields[2].name}
                onChangeByPropName={onChangeByPropName}
                labelKey={fields[2].labelKey}
                uniqueKey={fields[2].uniqueKey}
              />
            </S.Column>
            <S.Column>
              <S.CategoryTitle>{fields[0].label}</S.CategoryTitle>
              <FieldSwitcher
                type={fields[0].type}
                options={fields[0].options}
                value={values[fields[0].name]}
                name={fields[0].name}
                onChangeByPropName={onChangeByPropName}
                labelKey={fields[0].labelKey}
                uniqueKey={fields[0].uniqueKey}
              />
            </S.Column>
          </>
        ) : (
          <>
            <S.Column>
              <S.CategoryTitle>{fields[0].label}</S.CategoryTitle>
              <FieldSwitcher
                type={fields[0].type}
                options={fields[0].options}
                value={values[fields[0].name]}
                name={fields[0].name}
                onChangeByPropName={onChangeByPropName}
                labelKey={fields[0].labelKey}
                uniqueKey={fields[0].uniqueKey}
              />
            </S.Column>
            {filtersToShow.map(({ label, type, options, name, labelKey, uniqueKey }, index) => {
              if (index === 0) {
                return null
              }
              const j = index + 1
              return (
                <CustomAccordion
                  key={j}
                  expanded={openPanels[j]}
                  onChange={() => {
                    setOpenPanels((prev) => {
                      const newState = [...prev]
                      newState[j] = !newState[j]
                      return newState
                    })
                  }}
                >
                  <AccordionSummary>
                    <S.Column>
                      <S.CategoryTitle>
                        {label}
                        <img src={arrowDown} alt="icon" style={{ marginLeft: '10px' }} />
                      </S.CategoryTitle>
                    </S.Column>
                  </AccordionSummary>
                  <AccordionDetails>
                    <S.Column>
                      <FieldSwitcher
                        type={type}
                        options={options}
                        value={values[name]}
                        name={name}
                        onChangeByPropName={onChangeByPropName}
                        labelKey={labelKey}
                        uniqueKey={uniqueKey}
                      />
                    </S.Column>
                  </AccordionDetails>
                </CustomAccordion>
              )
            })}
          </>
        )}
      </S.FilterSection>
    </>
  )
}
