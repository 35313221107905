import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState = {
  location: {},
  api: {},
  auto: {},
}

export type InitialState = {
  location?: Location
  api?: Api
  auto?: AutoComplete
}

export interface Location {
  locationLat?: number
  locationLng?: number
}

export interface Api {
  apiLat?: number
  apiLng?: number
}

export interface AutoComplete {
  autoLat?: number
  autoLng?: number
  city?: string
}
type SetLocationApi = PayloadAction<InitialState>

const locationApiSlice = createSlice({
  name: 'locationApi',
  initialState,
  reducers: {
    setIp(state, { payload }: SetLocationApi) {
      state.api = payload
    },
    setLocation(state, { payload }: SetLocationApi) {
      state.location = payload
    },
    setAutoComplete(state, { payload }: SetLocationApi) {
      state.auto = payload
    },
    resetAutoComplete(state) {
      state.auto = {}
    },
  },
})

export const { setLocation, setIp, setAutoComplete, resetAutoComplete } = locationApiSlice.actions

export const locationApiReducer = locationApiSlice.reducer
