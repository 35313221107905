import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 310px;
  overflow-y: auto;
`
export const CategoryDiv = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
`
export const CategoryOption = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16.5579px;
  line-height: 18px;
  max-width: 10rem;
  text-align: left;
  /* or 107% */

  letter-spacing: 0.265725px;

  color: #4d4d4d;
  margin-left: 5px;
`
export const ClearButton = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height, or 128% */
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 2%;
  margin-bottom: 2%;

  letter-spacing: 0.240724px;

  color: #ebb62f;
  cursor: pointer;
`
export const ButtonContainer = styled.div`
  background: #fffbf4;
  border: 1px solid #ebb62f;
  box-sizing: border-box;
  border-radius: 30px;
  margin-top: 5%;
  width: 80%;
`
