import styled, { css } from 'styled-components'

export const link = css`
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 5px;
  letter-spacing: 0.28px;
  color: #062a20;
  margin: 0px 16px;
  &:hover {
    color: 'grey';
    text-decoration-color: 'white';
  }
`

export const Container = styled.header`
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(6, 42, 32, 0.11);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const Logo = styled.div``

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Option = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* or 111% */
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;

  &:hover {
    color: #1d1d1c;
  }

  width: 70px;
`
export const ExternalLink = styled.a`
  ${link};
`

export const Image = styled.img`
  /* width: 136px; */
  height: 42.43px;
  cursor: pointer;
`
