import { ServiceResponse } from '@types'
import axios from 'axios'

export const apiNear = async (lat: number, lng: number, limit: number): Promise<ServiceResponse> => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/business/global-search?lat=${lat}&lng=${lng}&limit=${limit}`
    )
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
