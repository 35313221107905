import { useSelector } from 'react-redux'
import { userSelector } from 'ducks/user'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

const UseAuthentication = (): void => {
  const user = useSelector(userSelector)
  const history = useHistory()

  useEffect(() => {
    if (user.token) {
      history.push('/profile')
    }
    // eslint-disable-next-line
  }, [user.token])
}

export default UseAuthentication
