import { FC } from 'react'

export const CancelIcon: FC = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.625 1.60875L11.3913 0.375L6.5 5.26625L1.60875 0.375L0.375 1.60875L5.26625 6.5L0.375 11.3913L1.60875 12.625L6.5 7.73375L11.3913 12.625L12.625 11.3913L7.73375 6.5L12.625 1.60875Z"
      fill="#EBB62F"
    />
  </svg>
)
