import { Radio } from 'pretty-checkbox-react'
import { FC } from 'react'
import { Container, CategoryDiv, CategoryOption } from './styled'

interface Props {
  options: any
  value: string
  onChange: (value: string) => void
  uniqueKey: string
  labelKey: string
}

export const RadioCheckBox: FC<Props> = ({ options, value, onChange, uniqueKey, labelKey }) => {
  //Methods
  const handleOnChange = (v: string) => () => {
    if (v === value) {
      onChange('')
    } else {
      onChange(v)
    }
  }

  return (
    <Container>
      {options.map((item: any) => {
        return (
          <CategoryDiv onClick={handleOnChange(item[uniqueKey])}>
            <Radio checked={value === item[uniqueKey]} style={{ cursor: 'pointer' }} />
            <CategoryOption>{item[labelKey]}</CategoryOption>
          </CategoryDiv>
        )
      })}
    </Container>
  )
}
