import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background-color: #fffbf4;
  padding-bottom: 4%;
  justify-content: center;
`

export const Card = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
  align-items: center;
  margin-top: 4%;
  margin-left: -3%;
`

export const BackIcon = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;
`

export const IconCard = styled.div`
  @media screen and (max-width: 1230px) {
    margin-left: 5%;
    margin-right: 3%;
  }

  @media screen and (max-width: 670px) {
    margin-top: -5%;
    margin-bottom: 5%;
  }
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  max-height: 440px;
  box-shadow: -5px 0px 21px 0px rgba(189, 189, 189, 0.33);
  border-radius: 32.7642px 32.7642px 0px 0px;
  position: relative;

  @media screen and (max-width: 1230px) {
    flex-direction: column-reverse;
    max-height: none;
    overflow: hidden;
  }
`

export const TopSection = styled.div`
  display: flex;

  @media screen and (max-width: 1230px) {
    width: 100%;
  }
`

export const TopInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 3% 5%;

  @media screen and (max-width: 968px) {
    width: 100%;
    padding: none;
  }

  @media screen and (max-width: 1230px) {
    margin-top: 30px;
    padding-right: 20px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const InfoSection = styled.div`
  height: 100%;
  width: 100%;
`

export const PrincipalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10%;
`
export const Name = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #062a20;

  @media screen and (max-width: 968px) {
    width: 100%;
  }
`

export const Distance = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 17.4743px;
  line-height: 15px;
  letter-spacing: 0.464749px;
  color: #062a20;
  margin-top: 2%;
  margin-bottom: 1%;
`

export const Keywords = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 199% */

  color: #8f7e60;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #dacdb6;
  border-radius: 7.48468px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  width: 50px;
`
export const Schedule = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #6fb750;
  width: auto;
  margin-top: 2%;
`

export const ScheduleClosed = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #df2c1f;
  width: 300px;
  margin-top: 10px;
`

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5%;
  align-items: center;
`
export const CallButton = styled.button`
  border: 1.5px solid #ad9f86;
  box-sizing: border-box;
  border-radius: 30px;
  width: 115px;
  height: 36px;
`

export const CardDetail = styled.div`
  width: 100%;

  @media screen and (max-width: 968px) {
    width: 100%;
    height: auto;
  }
`
export const CardPhoto = styled.div`
  width: 100%;
  border-radius: 0px 30px 0px 0px;
  max-height: 27vh;
  position: relative;
  // overflow: hidden;
`
export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 30px 0px 0px;
  align-self: stretch;
`

export const ImgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const CardDetailContainer = styled.div`
  background: #eff3e5;
  border-radius: 30px 30px 30px 30px;
  width: 80%;
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: center;
`
export const KeyWordsContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 2%;
`
export const ButtonOptions = styled.div`
  display: flex;
`

export const IconCall = styled.div<{ url?: string }>`
  mask: url('${({ url }) => url}') no-repeat 0px 0px / cover;
  mask-size: 100% 100%;
  background-color: #6fb750;
  margin-bottom: 10%;
  height: 18px;
  width: 18px;
`

export const Line = styled.span`
  border-left: 1px solid #6fb750;
  height: 24px;
  align-self: center;
  margin-left: 10px;
  // margin-right: 10px;
`

export const IconCopy = styled.img`
  height: 15px;
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
`

export const IconMail = styled.div<{ url?: string }>`
  mask: url('${({ url }) => url}') no-repeat 0px 0px / cover;
  mask-size: 100% 100%;
  background-color: #6fb750;
  margin-bottom: 10%;
  height: 15px;
  width: 20px;
`

export const IconShare = styled.div<{ url?: string }>`
  mask: url('${({ url }) => url}') no-repeat 0px 0px / cover;
  mask-size: 100% 100%;
  background-color: #6fb750;
  margin-bottom: 10%;
  height: 20px;
  width: 20px;
`

export const EmailShareButtonDetail = styled.a`
  border: 1.689px solid #6fb750;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 12.7572px;
  color: #6fb750;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  height: 60px;
  width: 60px;
  cursor: pointer;

  &:hover {
    background-color: #6fb750;
    color: #ffffff;
    & ${IconCall} {
      background-color: #ffffff;
    }
    & ${IconMail} {
      background-color: #ffffff;
    }
    & ${IconShare} {
      background-color: #ffffff;
    }
  }

  & .tool-tip {
    border-radius: 40px !important;
  }

  @media screen and (max-width: 330px) {
    margin-left: 5px;
  }
`

export const ShareButtonDisabled = styled.a`
  border: 1.689px solid #6fb750;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 12.7572px;
  // color: #6fb750;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  height: 60px;
  width: 60px;
  pointer-events: none;
  filter: opacity(37%);
  // background-color: grey;

  @media screen and (max-width: 330px) {
    margin-left: 5px;
  }
`
export const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(111, 183, 80, 0.14);
  }
`

export const FaceBookContainer = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(111, 183, 80, 0.14);
  }
`

export const IconMulti = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 10px;
`

export const TextShare = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 9px;
  color: #6fb750;
`

export const AboutTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #062a20;
`

export const Adress = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16.3821px;
  line-height: 19px;
  letter-spacing: 0.262904px;
  color: #062a20;
  margin-top: 10px;
`

export const AboutDetail = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* or 121% */

  text-align: left;

  color: #062a20;
  margin-top: 5%;

  @media screen and (max-width: 1458px) {
    width: 100%;
  }
`
export const WhyGreen = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 127% */

  color: #062a20;
  margin-top: 10%;

  @media screen and (max-width: 1458px) {
    margin-top: none;
    width: 50%;
    margin-top: 0%;
  }
`

export const WhyGreenDetail = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* or 121% */

  text-align: left;

  color: #062a20;
  margin-top: 5%;
`
export const RatingValueNumber = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 15.29px;
  line-height: 37px;
  /* or 243% */

  letter-spacing: 0.464749px;

  color: #062a20;
`
export const ArrowBack = styled.div`
  /* height: 120%;
  width: 120%; */

  @media screen and (max-width: 1230px) {
    background: #062a20;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
`

export const ArrowBackContainer = styled.div`
  display: flex;
  right: 70px;
  top: 20px;
  position: relative;

  @media screen and (max-width: 1230px) {
    top: -90%;
    left: 2%;
    position: relative;
    display: flex;
    z-index: 1000;
    width: 4%;
    height: 80px;
    border-radius: 50%;
  }
`
export const OptionText = styled.div``

export const InfoContainer = styled.div`
  background: #ffffff;
  box-shadow: -5.46071px 5.46071px 21.8428px rgba(189, 189, 189, 0.33);
  border-radius: 0px 0px 32.7236px 32.7236px;
`

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6%;
  margin-left: 40px;
`

export const Hours = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 17.9996px;
  line-height: 35px;
  color: #999999;
`

export const DaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`

export const Days = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20.2496px;
  line-height: 35px;
  color: #062a20;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 90%;

  @media screen and (max-width: 1230px) {
    /* justify-content: center; */
  }
`

export const Icon = styled.div<{ url?: string }>`
  mask: url('${({ url }) => url}') no-repeat 0px 0px / cover;
  mask-size: 100% 100%;
  height: 25px;
  background-color: black;
  width: 25px;
  transition: background-color 0.2s ease-out;
`

export const IconTwitter = styled.div`
  height: 25px;
  width: 25px;
`

export const IconShareTwitter = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`

export const Circle = styled.a`
  background-color: #eff3e5;
  border-radius: 50%;
  display: inline-block;
  height: 68px;
  width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  :hover {
    background-color: #062a20;

    & ${Icon} {
      background-color: #ffffff;
    }
  }
`

export const Circle2 = styled(Circle)`
  // margin-right: 0px;

  @media screen and (max-width: 1460px) {
    // margin-right: 10px;
  }
`

export const CircleBlurred = styled.a`
  filter: opacity(37%);
  background-color: grey;
  border-radius: 50%;
  display: inline-block;
  height: 68px;
  width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-right: 10px;
`

export const Circle2Blurred = styled(CircleBlurred)`
  // margin-right: 0px;
  @media screen and (max-width: 1230px) {
    /*     margin-right: 10px;
    margin-left: 10px; */
  }
`

export const Logo = styled.img`
  height: auto;
  width: 100px;
`

export const LogoDefault = styled.img`
  height: 60px;
  width: 60px;
`

export const Closed = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 19.1009px;
  line-height: 35px;
  color: #f05147;
`
export const Time = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #ad9f86;
  display: inline;
`

export const ButtonGo = styled.a`
  width: 77px;
  height: 32px;
  background: #ebb62f;
  border-radius: 32.7642px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 17.4743px;
  line-height: 7px;
  text-align: center;
  color: #ffffff;
  margin-left: 30px;
  cursor: pointer;
  transition-property: box-shadow, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

export const ButtonGoDisabled = styled.a`
  width: 77px;
  height: 32px;
  background: #ebb62f;
  border-radius: 32.7642px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 17.4743px;
  line-height: 7px;
  text-align: center;
  color: #ffffff;
  margin-left: 30px;
  filter: opacity(37%);
`

export const AddressTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconLocation = styled.img`
  height: 14px;
  margin-right: 7px;
`

export const LogoBusines = styled.img`
  margin-top: 0px;
  height: 25px;
  filter: contrast(100%);
`

export const LogoBusinessCircle = styled.div`
  border: 1.6869px solid #005d85;
  box-sizing: border-box;
  border-radius: 153.621px;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    background: #3bb5a6;
  }
  &:hover ${LogoBusines} {
    filter: brightness(0) invert(1);
  }
`
export const AboutSection = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1458px) {
    flex-direction: column;
    // margin-left: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }
`
export const AboutContainer = styled.div`
  margin-top: 5%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  width: 28%;
  margin-bottom: 5%;

  @media screen and (max-width: 1458px) {
    flex-direction: row;
    width: 100%;
  }

  @media screen and (max-width: 1230px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
  }
`
export const WhyGreenContainer = styled.div`
  @media screen and (max-width: 1458px) {
    margin-left: 50px;
    /* width: 50%; */
    margin-top: 0;
    width: 50%;
  }

  @media screen and (max-width: 1230px) {
    margin-left: 0px;
    /* width: 50%; */
    margin-top: 40px;
    width: 100%;
  }
`
export const AboutContainerInner = styled.div`
  @media screen and (max-width: 1458px) {
    width: 50%;
  }

  @media screen and (max-width: 1230px) {
    width: 100%;
  }
`

export const SecondAboutHours = styled.div`
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 2%;

  @media screen and (max-width: 1458px) {
    width: 35%;
  }

  @media screen and (max-width: 1230px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
  }
`
export const SecondAboutContainerInner = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 5%;

  @media screen and (max-width: 1458px) {
    flex-direction: row;
    display: flex;
  }

  @media screen and (max-width: 1230px) {
    flex-direction: column;
    display: flex;
  }
`
export const AdressContainer = styled.div`
  /* padding-left: 2%; */
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-bottom: 5%;
  margin-left: 5%;

  @media screen and (max-width: 1458px) {
    margin-left: 0%;
  }

  @media screen and (max-width: 1230px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 5%;
  }
`
export const SocialIcons = styled.div`
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-bottom: 5%;
  margin-left: 2%;
  margin-right: 2%;
  align-items: center;

  @media screen and (max-width: 1458px) {
    width: 22%;
  }

  @media screen and (max-width: 1230px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
`
