import { useMediaQuery } from '@mui/material'
import { staticSelector } from 'ducks/static'
import { useQueryRecovery } from 'hooks/useQueryRecovery'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { googleMapsContext, useGoogleMapsContext } from 'Views/Components/GoogleMapsComponent/context'
import { Header } from 'Views/Components/Header/index.'
import { NotFound } from 'Views/Components/NotFound'
import { SearchSearchBar } from 'Views/Components/SearchSearchBar'
import { BusinessDetail } from 'Views/Screens/BusinessDetail'
import Home from 'Views/Screens/Home'
import Login from 'Views/Screens/Login'
import Profile from 'Views/Screens/Profile'
import { Search } from 'Views/Screens/Search'
import get from 'lodash/get'
import { RedirectionFromDetails } from 'Views/Screens/BusinessDetail/RedirectionScreens'

const { Provider } = googleMapsContext

declare global {
  interface Document {
    mozFullScreenElement?: () => void
    webkitFullscreenElement?: () => void
    msFullscreenElement?: () => void
    webkitCurrentFullScreenElement?: () => void
  }
}

const NewRouter: FC = () => {
  const { handleQueryChange, defaultValues, currentValues } = useQueryRecovery()
  const { strapi } = useSelector(staticSelector)
  const isMobile = useMediaQuery('(max-width:633px)')
  const [fullscreen, setFullscreen] = useState(false)
  const [initialDimensions, setInitialDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    const funcionListener = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        document.webkitCurrentFullScreenElement
      const isFullscreen = !!fullscreenElement

      setFullscreen(isFullscreen)
      if (
        isFullscreen ||
        (initialDimensions.width === window.innerWidth && initialDimensions.height === window.innerHeight) ||
        /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      ) {
        return
      } else if (
        !isFullscreen &&
        !/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
      }
    }

    window.addEventListener('resize', funcionListener)

    return () => {
      window.removeEventListener('resize', funcionListener)
    }
  }, [isMobile, fullscreen, initialDimensions])

  const fields = useMemo((): any => {
    return [
      {
        type: 'RadioCheckBox',
        label: get(strapi, ['directorySearch', 'FilterOption', 0, 'Title'], ''),
        name: 'radius',
        options: get(strapi, ['directorySearch', 'FilterOption', 0, 'CategoryList'], []),
        labelKey: 'CategoryOption',
        uniqueKey: 'CategoryOption',
      },
      {
        type: 'MultipleCheckbox',
        label: get(strapi, ['directorySearch', 'CategoryFilter', 'Title'], ''),
        name: 'category',
        options: strapi?.categories?.filter(({ showFilters }: any) => showFilters),
        labelKey: 'name',
        uniqueKey: 'slug',
      },
      {
        type: 'MultipleCheckbox',
        label: 'Ownership',
        name: 'ow',
        options: [
          {
            id: 1,
            name: 'Woman -Owned.',
            slug: 'wo',
          },
          {
            id: 2,
            name: 'LGBTQIA+-Owned. ',
            slug: 'lg',
          },
          {
            id: 3,
            name: 'BIPOC -Owned.',
            slug: 'bi',
          },
          {
            id: 4,
            name: 'Veteran -Owned.',
            slug: 've',
          },
          {
            id: 5,
            name: 'Employee -owned.',
            slug: 'em',
          },
        ],
        labelKey: 'name',
        uniqueKey: 'slug',
      },
    ]
  }, [strapi])

  const onChangeByPropName = (propName: string, value: any) => {
    if (handleQueryChange) handleQueryChange({ [propName]: Array.isArray(value) ? value.join('-') : value })
  }

  const onSetFilters = (v: any) => {
    if (handleQueryChange) handleQueryChange(v)
  }

  const filterConfig = {
    fields,
    onChangeByPropName,
    onSetFilters: onSetFilters,
    values: currentValues,
    title: strapi?.directorySearch?.Filters?.Title,
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Header />
      <SearchSearchBar
        filterConfig={filterConfig}
        defaultValues={defaultValues}
        onQueryChange={handleQueryChange}
        onChangeByPropName={onChangeByPropName}
      />
      <Switch>
        <Route path="(/search|/find-business)">
          <Search
            filterConfig={filterConfig}
            currentValues={currentValues}
            defaultValues={defaultValues}
            handleQueryChange={handleQueryChange}
            strapi={strapi}
          />
        </Route>
        <Route path="/business/search" exact>
          <Search
            filterConfig={filterConfig}
            currentValues={currentValues}
            defaultValues={defaultValues}
            handleQueryChange={handleQueryChange}
            strapi={strapi}
          />
        </Route>
        <Route path="/business/details/:county/:name">
          <RedirectionFromDetails />
        </Route>
        <Route path="/details/:county/:name">
          <RedirectionFromDetails />
        </Route>
        <Route path="/business/:id">
          <BusinessDetail />
        </Route>
        <Route path="/find-business/business/:id">
          <BusinessDetail />
        </Route>
        <Route path="*">
          <Redirect to="/not-found" />
        </Route>
      </Switch>
    </div>
  )
}

const MainRouter: FC = () => {
  const ctx = useGoogleMapsContext()

  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/not-found">
        <NotFound />
      </Route>
      <Route path="/*">
        <Provider value={ctx}>
          <NewRouter />
        </Provider>
      </Route>
    </Switch>
  )
}

export default MainRouter
