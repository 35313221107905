import styled from 'styled-components'
import { Loading } from '../icons/Loading'

export const Container = styled.div`
  background-color: #062a20;
  height: 197px;
  align-items: center;
  display: flex;
`

export const Logo = styled.div`
  margin-left: 46px;
  margin-top: 18px;
`

export const TitleContainer = styled.div`
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  margin-left: 3%;
`

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 34px;
  color: #fffbf4;
`

export const Image = styled.img`
  width: 192px;
  height: 104px;
  padding-bottom: 10px;
`
export const Separador = styled.span`
  border-left: 0.7px solid #ffffff;
  height: 20px;
  align-self: center;
  margin-left: 0px;
  margin-right: 30px;
`

export const ContainerRow = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  padding: 0 1rem;
`

export const SearchBar = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 900px;
  @media screen and (max-width: 768px) {
    align-self: center;
  }
`

export const InputSearch = styled.div`
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  border: 2.5px solid #ffffff;
  flex-direction: row;
  display: flex;
  align-items: center;
  background: RGBA(45, 45, 45, 0.3);
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
`
export const IconSearch = styled.div`
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const InputTextSearch = styled.input`
  box-sizing: border-box;
  background-color: transparent;
  height: 28px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border: 0px;
  margin-left: 0.5rem;
  color: white;
  outline: none;
  width: 100%;
  &::placeholder {
    color: white;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 456px) {
    font-size: 12px;
  }
`
export const ZipcodeSearch = styled.div`
  border: 2.5px solid #ffffff;
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  flex-basis: 0;
  flex-grow: 1;
  // min-width: 25vw;
`

export const IconLocation = styled.div<{ url?: string }>`
  mask: url('${({ url }) => url}') no-repeat 0px 0px / cover;
  mask-size: 100% 100%;
  background-color: #062a20;
  width: 20px;
  height: 20px;
`

export const IconZipCode = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    & ${IconLocation} {
      background-color: #dfa000;
    }
  }
`

export const ZipcodeText = styled.input`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background-color: #ffffff;
  color: #062a20;
  padding-left: 10px;
  height: 28px;
  border-width: 0px;
  color: black;
  outline: none;

  &::placeholder {
    color: #062a20;
  }
`

export const SearchButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  background-color: #6fb750;
  border: 2.5px solid #ffffff;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
  color: #ffffff;
  border-top-color: white;
  border-bottom-color: white;
  border-right-color: white;
  border-left-color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #ffffff;
  @media screen and (max-width: 600px) {
    width: 50px;
    & > a {
      vertical-align: middle;
      & > img {
        margin-bottom: -3px;
        margin-left: -2px;
      }
    }
  }
`

export const GoogleAutoCompleteStyle = styled.div`
  width: 100%;
  background-color: transparent;
  padding-right: 5px;

  & > div > div:nth-child(2) {
    border: 0px;
    background: transparent;
    box-shadow: none;
  }

  & [class$='control'] {
    padding: 0;
    align-items: stretch;
  }

  & [class$='valueContainer'] {
    padding: 0;
  }

  & [class$='container'] {
    padding: 0;
  }

  & [class$='options'] {
    background-color: white;
  }

  & [class$='singleValue'] {
    color: black;
  }
  & [class$='input'] {
    color: black;
    background-color: transparent;
    border: 0px;
  }
  & [class$='placeholder'] {
    color: black;
  }

  @media screen and (max-width: 768px) {
    & [class$='indicatorSeparator'] {
      display: none;
    }
    & [class$='indicatorContainer'] {
      padding: 0;
      & > svg {
        padding: 0;
        width: 1rem;
        height: 1rem;
      }
    }
  }
`

export const LoadingSpinner = styled(Loading)`
  height: 20px;
  width: 20px;
`
