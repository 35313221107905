import { ServiceResponse } from '@types'
import axios from 'axios'

export const getAll = async (): Promise<ServiceResponse> => {
  const query = `
  query {
    categories(sort: "list_order:asc") {
      id
      list_order
      categorySet: GbtCategories {
        label: Title
        id: Id
      }
      name: Name
      icon: Icon {
        url
      }
      searchType: SearchType
      slug: Slug
      showHome: ShowHome
      showFilters: ShowFilters
    }
    nationalState {
      stateInfo {
        title
        lat
        lng
       zoom
      stateNumberId
      }
    }
    directoryHome {
      HeaderLogos{
        Logo{
          url
        }
        DomainList
        Path
        ShowButton
        ButtonText
      }
        DirectoryHeader{
          Logo{
            url
          }
          TitleOption{
            Title
            Path
          }
          }
          DirectoryBanner{
            Banner{
              url
            }
            InsideBanner{
              Title
            }
            Search{
              Input
              Zipcode
              Button
            }
            Category{
              Title
              Icon{
                url
              }
            }
          }
          DirectoryFooter{
            Title
            Description
            FooterTitleOption{
              TitleOption
              AsnwerOption
            }
          }
        }
        directorySearch{
          nearBusiness {
            title
          }
          SearchHeader{
            Logo{
              url
            }
            TitleOption{
              Title
              Path
            }
          }
          SearchBar{
            title
            notFound
            Search{
              Input
              Zipcode
              Button
            }
          }
          Filters{
            Title
          }
          FilterOption{
            Title
            CategoryList{
              id
              CategoryOption
            }
          }
          CategoryFilter{
            Title
            OptionList{
              id
              Option
            }
          
          }
        }
    }
  `

  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, { query })
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
