import { FC, useEffect, useState } from 'react'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
import { useMediaQuery } from '@mui/material'
import { useDispatch } from 'react-redux'
import { businessApiSelector, resetBusinessApi } from 'ducks/businessApi'
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
  getLatLng,
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import { Link } from 'react-router-dom'
import { locationApiSelector, setAutoComplete, resetAutoComplete } from 'ducks/locationApi'
import ReactTooltip from 'react-tooltip'
import axios from 'axios'
import search from '../../../assets/search.png'
import target from '../../../assets/target.png'
import {
  SearchBar,
  InputSearch,
  IconSearch,
  InputTextSearch,
  ZipcodeSearch,
  IconZipCode,
  SearchButton,
  GoogleAutoCompleteStyle,
  LoadingSpinner,
  IconLocation,
  ContainerRow,
} from './styled'

interface Props {
  onQueryChange?: (searchValues: any) => void
  defaultValues?: any
  disableSagas?: boolean
}

export const SearchBarComponent: FC<Props> = ({ onQueryChange, defaultValues = {}, disableSagas }) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const [value4, setValue4] = useState<any>('')
  const state = useSelector(staticSelector)
  const locator = useSelector(locationApiSelector)
  const business = useSelector(businessApiSelector)
  const [, setResultado] = useState('Current Location')

  const [inputText, setInputText] = useState(defaultValues?.keyword || '')

  const [locationValue, setLocationValue] = useState({
    lat: defaultValues?.lat || 0,
    lng: defaultValues?.lng || 0,
  })

  const [autoCompleteLatitude, setAutoCompleteLatitude] = useState(defaultValues?.lat || 0)
  const [autoCompleteLongitude, setAutoCompleteLongitude] = useState(defaultValues?.lng || 0)

  const [currentLocation, setCurrentLocation] = useState('Current Location')
  const [firstTime, setFirstTime] = useState(true)

  const q = window.location.search.substring(1)
  const queryObject: any = queryString.parse(q)
  const stateId = queryObject?.state_id

  const stateObject = state?.strapi?.nationalState?.stateInfo?.find(
    // eslint-disable-next-line eqeqeq
    (id: any) => id.stateNumberId == stateId
  )

  useEffect(() => {
    if (locator?.location?.location?.locationLat) {
      setLocationValue({
        lat: locator.location.location.locationLat,
        lng: locator.location.location.locationLng,
      })
    } else if (locator?.api?.api?.apiLat) {
      setLocationValue({
        lat: locator.api.api.apiLat,
        lng: locator.api.api?.apiLng,
      })
    }
    // eslint-disable-next-line no-restricted-globals
  }, [
    locator?.location?.location?.locationLat,
    locator?.location?.location?.locationLng,
    locator?.api?.api?.apiLat,
    locator?.api?.api?.apiLng,
    autoCompleteLatitude,
    autoCompleteLongitude,
    currentLocation,
  ])
  useEffect(() => {
    if (value4.value) {
      geocodeByPlaceId(value4.value.place_id)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          if (lat === 38.8026097 && lng === -116.419389) {
            setAutoCompleteLatitude(39.5235)
            setAutoCompleteLongitude(-119.7961)
          } else if (lat === 37.6016892 && lng === -121.7195459) {
            setAutoCompleteLatitude(37.8515)
            setAutoCompleteLongitude(-122.268)
          } else {
            setAutoCompleteLatitude(lat)
            setAutoCompleteLongitude(lng)
            geocodeByLatLng({ lat, lng })
              .then((results) => setResultado(results[0].address_components[3].long_name))
              .catch((error) => console.error('error', error))
          }
        })
    }

    if (firstTime && stateObject) {
      setCurrentLocation(stateObject?.title)
      setFirstTime(false)
    } else if (firstTime) {
      axios
        .get(
          `${process.env.REACT_APP_ENDPOINT_URL}/city/location?latlng=${autoCompleteLatitude}, ${autoCompleteLongitude}`
        )
        .then((data) => {
          // eslint-disable-next-line no-console
          setCurrentLocation(data.data.city)
          setFirstTime(false)
        })
    }
  }, [value4, autoCompleteLatitude, autoCompleteLongitude, currentLocation, firstTime, state, stateObject])

  const searchClick = () => {
    dispatch(
      setAutoComplete({
        auto: {
          autoLat: autoCompleteLatitude,
          autoLng: autoCompleteLongitude,
          city: value4?.value?.description,
        },
      })
    )
    dispatch(resetBusinessApi())
    if (inputText.length === 0 && value4?.value?.structured_formatting?.main_text.length > 0) {
      if (onQueryChange) onQueryChange({ lat: autoCompleteLatitude, lng: autoCompleteLongitude, keyword: '' })
    }
    if (inputText.length === 0 && value4?.value?.structured_formatting?.main_text === undefined) {
      if (onQueryChange) onQueryChange({ lat: locationValue.lat, lng: locationValue.lng, keyword: '' })
    }

    if (inputText.length > 0) {
      if (value4?.value?.structured_formatting?.main_text.length > 0) {
        if (onQueryChange) onQueryChange({ lat: autoCompleteLatitude, lng: autoCompleteLongitude, keyword: inputText })
      } else {
        if (onQueryChange) onQueryChange({ lat: locationValue.lat, lng: locationValue.lng, keyword: inputText })
      }
    }
  }

  const enterKey = (event: any) => {
    if (event.keyCode === 13) {
      document.getElementById('searchButtonBar')?.click()
      searchClick()
    }
  }

  const [flag, setFlag] = useState(true)

  const toggleFlag = () => {
    setFlag((v) => !v)
  }

  const clearAutoComplete = () => {
    setValue4('')
    dispatch(resetAutoComplete())
    setAutoCompleteLatitude(0)
    setAutoCompleteLongitude(0)
    toggleFlag()
    if (disableSagas) return
    if (onQueryChange) onQueryChange({ lat: 0, lng: 0, keyword: inputText })
  }

  const showPlaceHolder = () => {
    if (currentLocation === undefined) {
      return 'Current Location'
    } else {
      return currentLocation
    }
  }

  const inputCheck = (value: any) => {
    setInputText(value.target.value)
    if (value.target.value === '') {
      if (onQueryChange) onQueryChange({ keyword: '' })
    }
  }

  return (
    <ContainerRow>
      <SearchBar>
        <InputSearch onKeyDown={(e) => enterKey(e)}>
          {!isMobile && (
            <IconSearch>
              <img src={search} width="13.3px" height="13.3px" alt="icon" />
            </IconSearch>
          )}
          <InputTextSearch
            defaultValue={defaultValues?.keyword}
            placeholder={state?.strapi?.directorySearch?.SearchBar?.Search?.Input}
            onChange={inputCheck}
          ></InputTextSearch>
        </InputSearch>
        <ZipcodeSearch>
          <IconZipCode onClick={() => clearAutoComplete()} data-tip data-for="reset-location">
            <IconLocation url={target} />
          </IconZipCode>
          <ReactTooltip
            id="reset-location"
            place="top"
            effect="solid"
            backgroundColor="white"
            textColor="#062A20"
            border={true}
            borderColor="#062A20"
            clickable={true}
            delayHide={200}
            className="tool-tip"
          >
            Go to current location
          </ReactTooltip>
          <GoogleAutoCompleteStyle>
            <GooglePlacesAutocomplete
              key={`google-maps-autocomplete-${flag}`}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['us'],
                },
                types: ['(regions)'],
              }}
              selectProps={{
                value4: { ...value4, label: value4.label },
                onChange: setValue4,
                placeholder: showPlaceHolder(),
              }}
            />
          </GoogleAutoCompleteStyle>
        </ZipcodeSearch>
        {!business?.loading ? (
          <SearchButton onClick={() => searchClick()}>
            <Link to="/search" id="searchButtonBar">
              {isMobile ? (
                <img src={search} width="16px" height="16px" alt="icon" />
              ) : (
                state?.strapi?.directoryHome?.DirectoryBanner?.Search?.Button
              )}
            </Link>
          </SearchButton>
        ) : (
          <SearchButton>
            <Link to="/search" id="searchButtonBar">
              <LoadingSpinner />
            </Link>
          </SearchButton>
        )}
      </SearchBar>
    </ContainerRow>
  )
}
