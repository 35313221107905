import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* max-width: 1870px; */
  overflow-y: auto;
  overflow-x: hidden;
`
