import React, { FC, useEffect, useState } from 'react'
import { NotFound } from '../NotFound'
import { Container, Logo } from './styled'

export const LoaderPage: FC = () => {
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlag(true)
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  return <Container>{flag ? <NotFound /> : <Logo />}</Container>
}
