import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  detail: {},
}

export type InitialState = typeof initialState

const apiBusinessDetailSlice = createSlice({
  name: 'apiBusinessDetail',
  initialState,
  reducers: {
    setBusinessDetail(state, action) {
      state.detail = action.payload
    },
  },
})

export const { setBusinessDetail } = apiBusinessDetailSlice.actions

export const businessDetailReducer = apiBusinessDetailSlice.reducer
