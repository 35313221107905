export const getTagItems = ({ category, sub_category, lat, lng, limit, ...values }: any) => {
  const tagItems: Array<{ value: string; property: string }> = []

  if (category) {
    tagItems.push(
      ...category
        .split('-')
        .filter(Boolean)
        .map((value: string) => ({ property: 'category', value }))
    )
  }
  if (sub_category) {
    tagItems.push(
      ...sub_category
        .split('-')
        .filter(Boolean)
        .map((value: string) => ({ property: 'sub_category', value }))
    )
  }

  tagItems.push(
    ...Object.keys(values)
      .map((property: string) => ({ property, value: values[property] }))
      .filter((t) => !!t.value)
  )
  return tagItems
}
