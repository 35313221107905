import { FC, useContext, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { businessApiSelector } from 'ducks/businessApi'
import { useHistory } from 'react-router-dom'
import { locationApiSelector } from 'ducks/locationApi'
import currentLocation from '../../../assets/currentLocation.png'
import { MapContainer, PlaceFilterButton } from './styled'
import mapStyle from './mapStyles.json'
import { googleMapsContext } from './context'
import { MarkerComponent } from './MarkerComponent'

const MARKER_WINDOW_SIZE = 50

const AnyReactComponent: FC<any> = () => (
  <div>
    <img src={currentLocation} alt="CurrentLocation" style={{ height: '25px', width: '25px' }} />
  </div>
)

export const GoogleMapsComponent: FC<{ markers: any[]; values: any; handleQueryChange: any }> = ({
  markers,
  values,
  handleQueryChange,
}) => {
  const locator = useSelector(locationApiSelector)
  const history = useHistory()
  const { loading } = useSelector(businessApiSelector)
  const [showPlaceFilterer, setShowPlaceFilterer] = useState(false)
  const [mapState, setMapState] = useContext(googleMapsContext)
  const radius = values.radius

  useEffect(() => {
    const numberRadius = `${radius}`.match(/\d+/g)?.join('')
    if (radius) {
      setMapState((v) => ({ ...v, zoom: numberRadius ? Math.log2(80000 / (Number(numberRadius) * 1.60934)) : 15 }))
    }
  }, [radius, setMapState])

  const { startIndex, markerHoverId } = mapState
  const { lat = 0, lng = 0 } = values || {}

  const mapCoors = useMemo(() => {
    setMapState((v) => ({ ...v, center: { lat, lng } }))
    return {
      center: {
        lat,
        lng,
      },
    }
  }, [lat, lng, setMapState])

  const markersWindow = useMemo(() => {
    const floor = markers.length - MARKER_WINDOW_SIZE <= 0 ? 0 : markers.length - MARKER_WINDOW_SIZE

    return markers.slice(
      markers.length - startIndex <= MARKER_WINDOW_SIZE ? floor : startIndex,
      startIndex + MARKER_WINDOW_SIZE
    )
  }, [markers, startIndex])

  const handleDragEnd = (e: any) => {
    setShowPlaceFilterer(true)
    setMapState({
      ...mapState,
      center: {
        lat: e.center.lat(),
        lng: e.center.lng(),
      },
      zoom: e.zoom,
    })
  }

  useEffect(() => {
    if (!loading) {
      setShowPlaceFilterer(false)
    }
  }, [loading, setShowPlaceFilterer])

  const handlePlaceFiler = () => {
    handleQueryChange({ lat: mapState.center.lat, lng: mapState.center.lng })
  }

  return (
    <MapContainer>
      {showPlaceFilterer && (
        <PlaceFilterButton className={loading ? 'is-loading' : ''} onClick={handlePlaceFiler}>
          Search in this area
          <img
            src="https://gbn-national-site-api-bucket.s3.us-east-2.amazonaws.com/reset_1_6ceb40c9b0.png"
            alt="reload"
          />
        </PlaceFilterButton>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_ACCESS_KEY}` }}
        center={mapState.center}
        options={mapStyle}
        zoom={mapState.zoom}
        onDragEnd={handleDragEnd}
      >
        <AnyReactComponent
          lat={locator?.location?.location?.locationLat || locator?.api?.api?.apiLat}
          lng={locator?.location?.location?.locationLng || locator?.api?.api?.apiLng}
        />
        {markersWindow.map((item, index: number) => (
          <MarkerComponent
            key={`marker-item-render-${item.slug2}-${index}`}
            {...item}
            markerHoverId={markerHoverId}
            setMarkerHover={setMapState}
            history={history}
            index={index}
          />
        ))}
      </GoogleMapReact>
    </MapContainer>
  )
}
