export const DeleteFilterItemFromValue = (property: string, value: string, values: any) => {
  const newValues = { ...values }

  switch (property) {
    case 'lat':
    case 'lng':
      delete newValues.lat
      delete newValues.lng
      break
    case 'category':
      newValues[property] = values[property].replace(value, '').split('-').filter(Boolean).join('-')
      break
    case 'limit':
      break
    default:
      newValues[property] = ''
      break
  }

  return newValues
}
