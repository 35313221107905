import { createSelector } from '@reduxjs/toolkit'

// TODO: import Store type and add reducer type in Store interface
const getStatic = (state: any) => state.staticContent

export const staticSelector = createSelector(getStatic, (staticContent) => staticContent)

const noresult: any[] = []

export const categoriesSelector = createSelector(
  getStatic,
  (staticContent) => staticContent?.strapi?.categories || noresult
)
