import { FC } from 'react'
import LoginForm from 'Views/Components/Forms/Login'
import UseAuthentication from 'hooks/useAuthentication'
import { Container } from './styled'

// test
const Login: FC = () => {
  UseAuthentication()

  return (
    <Container>
      <span>Login Screen works!</span>
      <LoginForm />
    </Container>
  )
}

export default Login
