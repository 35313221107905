const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const getDistanceFromLatLng = (latBusiness: number, lngBusiness: number, latUser: number, lngUser: number) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(latUser - latBusiness)
  const dLon = deg2rad(lngUser - lngBusiness)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(latBusiness)) * Math.cos(deg2rad(latUser)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return Math.round((d / 1.609 + Number.EPSILON) * 100) / 100
}
