import { ServiceResponse } from '@types'
import axios from 'axios'
import queryString from 'query-string'
import { parseQueryValues } from 'lib/parsers'

export const getBusinessByQuery = async (queryObject: any): Promise<ServiceResponse> => {
  try {
    const queryObjectFilter: any = {}
    const queryFilter = [
      'page',
      'limit',
      'keyword',
      'lat',
      'lng',
      'radius',
      'category',
      'sub_category',
      'city',
      'from_lp',
      'state_id',
      'ow',
    ]
    queryFilter.forEach((element) => {
      if (element in queryObject) {
        queryObjectFilter[element] = queryObject[element]
      }
    })
    const data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_URL}/business/global-search?${queryString.stringify(
        parseQueryValues(queryObjectFilter)
      )}`
    )
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
