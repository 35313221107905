import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const Name = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  background: #6fb750;
  color: white;
  padding: 5px;
`

export const CardContainer = styled.div`
  width: 327px;
  height: auto;
  border-radius: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Logo = styled.img`
  height: 70px;
  width: 70px;
  object-fit: contain;
  // margin-right: 10px;
`

export const BusinessName = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #062a20;
`

export const Distance = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.226071px;
  color: #8f7e60;
`

export const OpenContainer = styled.div`
  margin-left: 5px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const Schedule = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #6fb750;
  width: 300px;
  margin-top: 2%;
`

export const ScheduleClosed = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #df2c1f;
`

export const RatingValueNumber = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 15.29px;
  line-height: 37px;
  letter-spacing: 0.464749px;
  color: #062a20;
`

export const SeeMore = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(0.7rem, 1vw, 1rem);
  line-height: 100%;
  letter-spacing: 0.240724px;
  color: #6fb750;
  padding-left: 7px;
`

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  position: relative;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const PlaceFilterButton = styled.div`
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%) scale(1);
  z-index: 9;
  height: 2rem;
  border-radius: 1rem;
  background: #ad9f86;
  color: #fff;
  display: flex;
  align-items: center;
  justify-center: center;
  padding: 0 1rem;
  box-shadow: 0px 1px 12px 2px rgba(173, 159, 134, 0.4);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  & img {
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
  &.is-loading {
    & img {
      animation: ${spin} 1s linear infinite;
    }
  }
  &:active {
    transform: translateX(-50%) scale(0.9);
  }

  @media screen and (max-width: 1354px) {
    font-size: 11px;
  }
`
export const LinkSpan = styled.span`
  cursor: pointer;
`

export const Wrapper = styled.div`
  width: 1.5rem;
  user-select: none;
  transform-origin: bottom;
  transform: translate(-50%, -100%);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  svg {
    filter: drop-shadow(0px 1px 1px #000);
  }
`
