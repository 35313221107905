/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { categoriesSelector } from 'ducks/static'
import { businessApiSelector } from 'ducks/businessApi'
import { useHistory } from 'react-router-dom'
import { InfiniteLoader, List } from 'react-virtualized'
import { businessSagas } from 'ducks/businessApi'
import { slugsToIds } from 'lib/parsers/categories'
import { useMediaQuery } from '@mui/material'
import { Helmet } from 'react-helmet'
import { GoogleMapsComponent } from '../GoogleMapsComponent'
import { LoaderSearch } from '../LoaderSearch'
import { googleMapsContext } from '../GoogleMapsComponent/context'
import greenBizIcon from '../../../assets/GreenbizIcon.png'
import iconPrueba from '../../../assets/iconPrueba.png'
import { Container, CardSection, MapSection, ResultContainer, CardControlIcon } from './styled'
import { CardComponent, getCardHeight } from './Card'
import { Filter } from './Filter'
import './styles.css'
import 'react-virtualized/styles.css'

interface Props {
  defaultValues?: any
  values: any
  filterConfig?: any
  handleQueryChange: (v: any) => void
}

export const SearchBody: FC<Props> = ({ values, filterConfig, handleQueryChange }) => {
  const mobileMode = useMediaQuery('(max-width: 1000px)')
  const [listWidth, setListWidth] = useState(0)
  const [listHight, setListHeight] = useState(0)
  const [isMobileSearchOpened, setIsMobileSearchOpened] = useState(false)
  const listContainer = useRef<any>(null)
  const businesses = useSelector(businessApiSelector)
  const categories = useSelector(categoriesSelector)
  const history = useHistory()
  const [{ scrollId }, setMapState] = useContext(googleMapsContext)
  const dispatch = useDispatch()
  const businessLimit = 10
  const [isMobile2, setIsMobile] = useState(false)
  const [mobileText, setMobileText] = useState(true)

  useEffect(() => {
    if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true)
    }
  }, [])

  const toggleMobileSearch = () => {
    setIsMobileSearchOpened(!isMobileSearchOpened)
    setMobileText(!mobileText)
  }

  const handleWidthChange = useCallback(() => {
    setListWidth(listContainer.current.clientWidth)
    setListHeight(listContainer.current.clientHeight)
  }, [setListWidth])

  useEffect(() => {
    window.addEventListener('resize', handleWidthChange)
    handleWidthChange()
    return () => {
      window.removeEventListener('resize', handleWidthChange)
    }
  }, [handleWidthChange, mobileMode])

  const { business, loading, error, refreshing } = businesses

  const currentHoverIndex = useMemo(() => {
    if (!business.length) return -1

    return business.findIndex(({ slug2 }: any) => slug2 === scrollId)
  }, [business, scrollId])

  const goToBussinesDetail = (id: any) => {
    history.push(`/business/${id}`)
  }

  const loadMoreRows = refreshing
    ? () => {}
    : async ({ startIndex }: any) => {
        const query = {
          ...values,
          limit: businessLimit,
          radius: `${values.radius}`.match(/\d+/g)?.join(''),
          page: Math.round(startIndex / 10) + 1,
          ...slugsToIds(`${values.category || ''}`, categories),
        }
        dispatch(businessSagas.run({ query, refresh: true }))
      }

  function rowRenderer({ key, index, style }: any) {
    if (!business[index]) return null
    const {
      google_data,
      yelp_data,
      slug2,
      name,
      logo,
      distance,
      phone: phoneTxt,
      google_place_id,
      google_link,
      street_address,
      image,
      city,
      state,
      lat,
      lng,
      hide_address,
    } = (business[index] || {}) as any
    return (
      <>
        <CardComponent
          key={`directory-search-item-${index}${key}`}
          uniqueKey={`directory-search-item-${index}`}
          logoUrl={logo}
          slug2={slug2}
          name={name}
          scrollId={scrollId}
          distance={distance}
          rating={google_data?.result?.rating || yelp_data?.rating}
          openNow={google_data?.result?.opening_hours?.open_now}
          openNowYelp={yelp_data?.hours && yelp_data?.hours[0].is_open_now}
          googleHours={google_data?.result?.opening_hours}
          googleId={google_data?.result?.place_id}
          placeId={google_place_id}
          yelpHours={yelp_data?.hours}
          phone={phoneTxt}
          googleLink={google_link}
          address={street_address}
          city={city}
          state={state}
          lat={lat}
          lng={lng}
          goToBussinesDetail={goToBussinesDetail}
          imageUrl={image}
          style={style}
          hide_address={hide_address}
        />
      </>
    )
  }

  function isRowLoaded({ index }: any) {
    return !!business[index]
  }

  return (
    <Container className={mobileMode ? 'o-column' : ''}>
      <Helmet>
        <link rel="icon" href={greenBizIcon} />
        <title>Greenbiz Tracker</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Greenbiz Tracker" />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:description" content="A Recognized Green Business" />
        <meta property="og:image" content={iconPrueba} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Greenbiz Tracker" />
        <meta name="twitter:description" content="A Recognized Green Business" />
        <meta name="twitter:image" content={iconPrueba} />
      </Helmet>
      {!mobileMode && (
        <Filter
          mobileMode={mobileMode}
          title={filterConfig.Title}
          fields={filterConfig.fields}
          values={filterConfig.values}
          onChangeByPropName={filterConfig.onChangeByPropName}
        />
      )}
      <ResultContainer className={mobileMode ? 'o-mobile-mode' : ''}>
        <CardSection ref={listContainer} className={isMobileSearchOpened ? 'o-is-open' : ''}>
          {mobileMode && (
            <CardControlIcon onClick={toggleMobileSearch}>
              {mobileText ? (
                <span style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  Tap here to see more!
                </span>
              ) : (
                <span style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  Tap here to see less
                </span>
              )}
            </CardControlIcon>
          )}
          <LoaderSearch loading={loading} error={error}>
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows as any}
              rowCount={business.length + 11}
            >
              {({ onRowsRendered, registerChild }: any) => (
                <List
                  scrollToIndex={currentHoverIndex}
                  scrollToAlignment="start"
                  height={mobileMode ? window.innerHeight : listHight}
                  onRowsRendered={(...args: any) => {
                    setMapState((v: any) => ({ ...v, startIndex: args[0].startIndex }))
                    onRowsRendered(...args)
                  }}
                  ref={registerChild}
                  rowHeight={({ index }: any) => {
                    return business[index] ? getCardHeight(isMobile2) : 193
                  }}
                  rowRenderer={rowRenderer}
                  width={listWidth}
                  rowCount={business.length + 11}
                />
              )}
            </InfiniteLoader>
          </LoaderSearch>
        </CardSection>
        <MapSection>
          <GoogleMapsComponent handleQueryChange={handleQueryChange} values={values as any} markers={business} />
        </MapSection>
      </ResultContainer>
    </Container>
  )
}
