import { staticSelector } from 'ducks/static'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { Container, Logo, ExternalLink, Options, Option, Image } from './styled'

export const Header: FC = () => {
  const state = useSelector(staticSelector)
  const isMobile = useMediaQuery('(min-width:633px)')
  const url = window.location.href.toLowerCase()

  const findLogo = state?.strapi?.directoryHome?.HeaderLogos?.find((domain: any) =>
    domain.DomainList.includes(window.location.host)
  )

  useEffect(() => {}, [findLogo, state])

  return (
    <Container>
      <Logo
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginLeft: isMobile ? '28px' : '5%',
        }}
      >
        {findLogo?.Logo?.url ? (
          <Image
            src={findLogo?.Logo?.url}
            alt="domain-image"
            onClick={() => {
              window.location.href = findLogo?.Path || '/'
            }}
          />
        ) : (
          <Image
            src={
              isMobile
                ? state?.strapi?.directoryHome?.DirectoryHeader[0]?.Logo[0]?.url
                : state?.strapi?.directoryHome?.DirectoryHeader[0]?.Logo[1]?.url
            }
            alt="image"
            onClick={() => {
              window.location.href = '/'
            }}
          />
        )}
      </Logo>
      <Options>
        {findLogo?.ShowButton && findLogo?.ButtonText ? (
          <>
            <div
              key="header-links-home"
              style={{
                backgroundColor: '#ffffff',
                height: '80px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <ExternalLink href={findLogo.Path || '/'}>
                <Option
                  style={{
                    color: '#062A20',
                  }}
                >
                  {findLogo.ButtonText}
                </Option>
              </ExternalLink>
            </div>
          </>
        ) : null}
        {state?.strapi?.directoryHome?.DirectoryHeader[0]?.TitleOption?.map((item: any, index: number) => {
          return (
            <>
              <div
                key={`header-links-${index}`}
                style={{
                  backgroundColor:
                    url.includes('search') && `header-links-${index}` === `header-links-0` ? '#153e7c' : '#ffffff',
                  height: '80px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <ExternalLink key={item.Title} href={item.Path}>
                  <Option
                    style={{
                      color:
                        url.includes('search') && `header-links-${index}` === `header-links-0` ? '#ffffff' : '#062A20',
                    }}
                  >
                    {item.Title}
                  </Option>
                </ExternalLink>
              </div>
            </>
          )
        })}
      </Options>
    </Container>
  )
}
