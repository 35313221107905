import styled from 'styled-components'

export const Container = styled.div``
export const CategoryOption = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16.5579px;
  line-height: 18px;
  /* or 107% */

  letter-spacing: 0.265725px;

  color: #4d4d4d;
  margin-left: 5px;
`
export const CategoryDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  cursor: pointer;
`
