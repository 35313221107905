import { FC } from 'react'
import markerIcon from '../../../assets/markerDisabled.png'
import markerIcon2 from '../../../assets/marker2Hover.png'
import { Container, Wrapper } from './styled'

export const MarkerComponent: FC<any> = (item) => {
  const showTooltipByProp = () => {
    item.setMarkerHover((v: any) => ({ ...v, markerHoverId: item.slug2, scrollId: item.slug2 }))
  }

  const hideTooltipByProp = () => {
    item.setMarkerHover((v: any) => ({ ...v, markerHoverId: '' }))
  }

  const { markerHoverId } = item

  const handleClick = () => {
    item.history.push(`/business/${item.slug2}`)
  }

  return (
    <>
      <Wrapper onMouseEnter={showTooltipByProp} onMouseLeave={hideTooltipByProp} id={`marker-map-${item.index}`}>
        <Container data-tip>
          <img
            src={markerHoverId === item.slug2 ? markerIcon2 : markerIcon}
            alt="markersMarker"
            style={{ height: '28px', width: '28px', objectFit: 'scale-down' }}
            onClick={handleClick}
          />
        </Container>
      </Wrapper>
    </>
  )
}
