import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export const RedirectionFromDetails: FC = () => {
  const { name } = useParams<any>()
  const history = useHistory()
  history.push(`/business/${name}`)

  return (
    <div>
      <p>RedirectionScreens</p>
    </div>
  )
}
