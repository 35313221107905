import { staticSelector } from 'ducks/static'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Container, Content, Logo, TextNotFound } from './styled'

interface Props {
  loading?: boolean
  error?: string
}

export const LoaderSearch: FC<Props> = ({ loading, error, children }) => {
  const state = useSelector(staticSelector)

  if (loading) {
    return (
      <Container>
        <Logo />
      </Container>
    )
  }

  if (error) {
    return (
      <Content>
        <TextNotFound>{state.strapi.directorySearch.SearchBar.notFound}</TextNotFound>
      </Content>
    )
  }

  return <>{children}</>
}
