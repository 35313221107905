import { staticSelector } from 'ducks/static'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiNear } from 'services/apiNear'
import { Link, useHistory } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
import Rating from '@mui/material/Rating'
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
// import phone from 'assets/phone.png'
// import location from 'assets/location.png'
import { locationApiSelector } from 'ducks/locationApi'
// import { apiServiceDetail } from 'services/apiDetailBusiness'
import { openOrClosed, openOrClosedYelp } from '../BussinesCardDetailComponent/getHours'
import iconPrueba from '../../../assets/iconPrueba.png'
// import iconCopy from '../../../assets/shareCopyBrown.png'
import { getDistanceFromLatLng } from '../BussinesCardDetailComponent/getDistanceFromLatLng'
// import { Data } from '../BussinesCardDetailComponent/details'
import {
  Container,
  Content,
  Title,
  CardContainer,
  Card,
  TopSection,
  IconContainer,
  Icon,
  TitleSection,
  CardTitle,
  Distance,
  InfoSection,
  // ButtonSection,
  // CallButton,
  // SeeMore,
  // IconCall,
  // IconLocation,
  // SeeMoreButton,
  RatingValueNumber,
  Schedule,
  // Time,
  CardContent,
  RatingContainer,
  // Line,
  // IconCopy,
  // CallButtonDisabled,
  // DirectionButton,
  // DirectionButtonDisabled,
  ScheduleClosed,
} from './styled'

// const MySwal = withReactContent(Swal)

export const SearchNear: FC = () => {
  const state = useSelector(staticSelector)
  const locator = useSelector(locationApiSelector)

  const [near, setNear] = useState<any>()

  const { title } = state.strapi.directorySearch.nearBusiness

  const businessLimit = 4

  useEffect(() => {
    if (locator?.location?.location?.locationLat) {
      apiNear(locator?.location?.location?.locationLat, locator?.location?.location?.locationLng, businessLimit).then(
        (response) => {
          setNear(response?.data?.data?.data)
        }
      )
    } else {
      if (locator?.api?.api?.apiLat) {
        apiNear(locator?.api?.api?.apiLat, locator?.api?.api?.apiLng, businessLimit).then((response) => {
          setNear(response?.data?.data?.data)
        })
      }
    }
  }, [
    locator?.location?.location?.locationLat,
    locator?.location?.location?.locationLng,
    locator?.api?.api?.apiLat,
    locator?.api?.api?.apiLng,
  ])

  // const history = useHistory()
  // const goToBussinesDetail = (id: any) => {
  //   history.push(`/business/${id}`)
  // }

  const locationUser = () => {
    let userLocation = { lat: 0, lng: 0 }
    if (locator?.location?.location?.locationLat) {
      userLocation = { lat: locator.location.location.locationLat, lng: locator.location.location.locationLng }
    } else {
      userLocation = { lat: locator?.api?.api?.apiLat, lng: locator?.api?.api?.apiLng }
    }
    return userLocation
  }

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <CardContainer>
          {near && near.length
            ? near.map((item: any, index: any) => {
                return (
                  <Card key={`directorySearchNear-${index}`}>
                    <CardContent>
                      <TopSection>
                        <IconContainer>
                          <Link to={`/business/${item.slug2}`}>
                            {item.logo ? <Icon src={item.logo} alt="icon" /> : <Icon src={iconPrueba} alt="icon" />}
                          </Link>
                        </IconContainer>
                        <TitleSection>
                          <Link to={`/business/${item.slug2}`}>
                            <CardTitle>{item.name}</CardTitle>
                          </Link>
                          <Distance>
                            {getDistanceFromLatLng(item.lat, item.lng, locationUser().lat, locationUser().lng)} miles
                            away
                          </Distance>
                          {item.google_data && item.google_data.result?.rating ? (
                            <RatingContainer>
                              <Rating name="read-only" value={item.google_data.result.rating} readOnly />
                              <RatingValueNumber>{item.google_data.result.rating}</RatingValueNumber>
                            </RatingContainer>
                          ) : item.yelp_data && item.yelp_data.rating ? (
                            <RatingContainer>
                              <Rating name="read-only" value={item.yelp_data.rating} readOnly />
                              <RatingValueNumber>{item.yelp_data.rating}</RatingValueNumber>
                            </RatingContainer>
                          ) : null}
                        </TitleSection>
                      </TopSection>
                      <InfoSection>
                        {item.google_data?.result?.opening_hours && item.google_data?.result?.opening_hours.open_now ? (
                          <Schedule>
                            Open now - {openOrClosed(item.google_data?.result?.opening_hours.periods)}
                          </Schedule>
                        ) : item.google_data?.result?.opening_hours &&
                          !item.google_data?.result?.opening_hours.open_now ? (
                          <ScheduleClosed>Closed</ScheduleClosed>
                        ) : item.yelp_data?.hours && item.yelp_data?.hours[0].is_open_now ? (
                          <Schedule>Open now - {openOrClosedYelp(item.yelp_data?.hours[0].open)}</Schedule>
                        ) : item.yelp_data?.hours && !item.yelp_data?.hours[0].is_open_now ? (
                          <ScheduleClosed>Closed</ScheduleClosed>
                        ) : null}
                        {/* <ButtonSection>
                          {item?.phone ? (
                            <>
                              <CallButton href={`tel:${item?.phone}`} data-tip data-for={`phoneCallNear-${index}`}>
                                <IconCall src={phone} />
                                Call
                              </CallButton>

                              <ReactTooltip
                                id={`phoneCallNear-${index}`}
                                place="top"
                                effect="solid"
                                backgroundColor="white"
                                textColor="#AD9F86"
                                border={true}
                                borderColor="#8F7E60"
                                clickable={true}
                                delayHide={200}
                                className="tool-tip-search"
                              >
                                {item.phone}
                                <Line />
                                <IconCopy
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.phone)
                                    MySwal.fire({
                                      title: 'Copied to clipboard',
                                      showConfirmButton: false,
                                      width: 400,
                                      background: '#fffbf4',
                                      timer: 1000,
                                      customClass: {
                                        container: 'swal-container',
                                        title: 'swal-title',
                                      },
                                    })
                                  }}
                                  src={iconCopy}
                                />
                              </ReactTooltip>
                            </>
                          ) : (
                            <CallButtonDisabled>
                              <IconCall src={phone} />
                              Call
                            </CallButtonDisabled>
                          )}
                          {item.google_data?.result?.place_id && item.google_data?.result?.place_id.length > 1 ? (
                            <>
                              <DirectionButton
                                href={`https://www.google.com/maps/dir/?api=1&destination=1&destination_place_id=${item.google_data?.result?.place_id}`}
                                target="_blank"
                                data-tip
                                data-for={`directions-${index}`}
                              >
                                <IconLocation src={location} />
                                Directions
                              </DirectionButton>
                              <ReactTooltip
                                id={`directions-${index}`}
                                place="top"
                                effect="solid"
                                backgroundColor="white"
                                textColor="#AD9F86"
                                border={true}
                                borderColor="#8F7E60;"
                                className="tool-tip-search"
                              >
                                {item.street_address}
                              </ReactTooltip>
                            </>
                          ) : item.google_place_id && item.google_place_id.length > 1 ? (
                            <>
                              <DirectionButton
                                href={`https://www.google.com/maps/dir/?api=1&destination=1&destination_place_id=${item.google_place_id}`}
                                target="_blank"
                                data-tip
                                data-for={`directions-${index}`}
                              >
                                <IconLocation src={location} />
                                Directions
                              </DirectionButton>
                              <ReactTooltip
                                id={`directions-${index}`}
                                place="top"
                                effect="solid"
                                backgroundColor="white"
                                textColor="#AD9F86"
                                border={true}
                                borderColor="#8F7E60;"
                                className="tool-tip-search"
                              >
                                {item.street_address}
                              </ReactTooltip>
                            </>
                          ) : item.street_address.length > 1 && item.city.length > 1 && item.state.length > 1 ? (
                            <>
                              <DirectionButton
                                href={`https://www.google.com/maps/dir/?api=1&destination=${item.street_address.replace(
                                  / /g,
                                  '+'
                                )}+${item.city.replace(/ /g, '+')}+${item.state}`}
                                target="_blank"
                                data-tip
                                data-for={`directions-street-${index}`}
                              >
                                <IconLocation src={location} />
                                Directions
                              </DirectionButton>
                              <ReactTooltip
                                id={`directions-street-${index}`}
                                place="top"
                                effect="solid"
                                backgroundColor="white"
                                textColor="#AD9F86"
                                border={true}
                                borderColor="#8F7E60;"
                                className="tool-tip-search"
                              >
                                {item.street_address}
                              </ReactTooltip>
                            </>
                          ) : item.lat && item.lng ? (
                            <>
                              <DirectionButton
                                href={`https://www.google.com/maps/dir/?api=1&destination=${item.lat},${item.lng}`}
                                target="_blank"
                                data-tip
                                data-for={`directions-latitude-${index}`}
                              >
                                <IconLocation src={location} />
                                Directions
                              </DirectionButton>
                              <ReactTooltip
                                id={`directions-latitude-${index}`}
                                place="top"
                                effect="solid"
                                backgroundColor="white"
                                textColor="#AD9F86"
                                border={true}
                                borderColor="#8F7E60;"
                                className="tool-tip-search"
                              >
                                Show directions
                              </ReactTooltip>
                            </>
                          ) : (
                            <DirectionButtonDisabled>
                              <IconLocation src={location} />
                              Directions
                            </DirectionButtonDisabled>
                          )}
                          <SeeMoreButton
                            onClick={() => {
                              goToBussinesDetail(item.slug2)
                            }}
                          >
                            <SeeMore>See More</SeeMore>
                          </SeeMoreButton>
                        </ButtonSection> */}
                      </InfoSection>
                    </CardContent>
                  </Card>
                )
              })
            : null}
        </CardContainer>
      </Content>
    </Container>
  )
}
