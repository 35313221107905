import styled from 'styled-components'
import { color, position, space, layout, LayoutProps, SpaceProps, ColorProps, PositionProps } from 'styled-system'
import { Link } from 'react-router-dom'

export type BlockProps = LayoutProps & SpaceProps & ColorProps & PositionProps

export const Button = styled.div<BlockProps>`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.main};
  color: #fff;
  padding: 1rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`

export const LinkButton = styled(Link)<BlockProps>`
  color: ${(props) => props.theme.colors.main};
  padding: 1rem;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`
