import { FC } from 'react'
import usePrivateRoute from 'hooks/usePrivateRoute'
import { Button } from 'Views/Components/UI/Buttons'
import { logout } from 'ducks/user'
import { useDispatch } from 'react-redux'
import { Container } from './styled'

const Profile: FC = () => {
  const user = usePrivateRoute()

  const dispatch = useDispatch()

  const handleLogout = (): void => {
    dispatch(logout())
  }

  return (
    <Container>
      <span>hello {`${user.email}`}</span>

      <Button m="1rem" onClick={handleLogout}>
        Logout
      </Button>
    </Container>
  )
}

export default Profile
