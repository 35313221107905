import { setStatic } from 'ducks/static'
import { useLocation } from 'hooks/useGeolocation'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { getAll } from 'services/getAll'
import MainRouter from './Main'

const Router: FC = () => {
  const dispatch = useDispatch()
  useLocation()

  useEffect(() => {
    getAll().then((response) => {
      dispatch(setStatic(response.data))
    })
  })

  return (
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  )
}
export default Router
