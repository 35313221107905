import { FC, useEffect, useState } from 'react'
import { LoaderPage } from 'Views/Components/LoaderPage'
import { SearchBody } from 'Views/Components/SearchBody'
import { SearchNear } from 'Views/Components/SearchNear'

interface Props {
  defaultValues?: any
  currentValues: any
  filterConfig?: any
  handleQueryChange: (v: any) => void
  strapi: any
}

export const Search: FC<Props> = ({ filterConfig, currentValues, defaultValues, handleQueryChange, strapi }) => {
  const [isMobile2, setIsMobile] = useState(true)
  const url = window.location.href.toLowerCase()
  const [showNear, setShowNear] = useState(false)

  useEffect(() => {
    if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(false)
    }

    if (!isMobile2) {
      setShowNear(false)
    } else {
      if (url.includes('category') || url.includes('keyword')) {
        setShowNear(true)
      } else {
        setShowNear(false)
      }
    }

    // console.log(showNear)
  }, [url, isMobile2, showNear])

  return (
    <>
      {strapi?.directoryHome ? (
        <>
          <SearchBody
            filterConfig={filterConfig}
            values={currentValues}
            defaultValues={defaultValues}
            handleQueryChange={handleQueryChange}
          />
          {showNear ? <SearchNear /> : null}
        </>
      ) : (
        <LoaderPage />
      )}
    </>
  )
}
