import { combineReducers } from '@reduxjs/toolkit'
import { staticReducer } from 'ducks/static'
import { userReducer } from 'ducks/user/reducer'
import { businessApiReducer } from 'ducks/businessApi'
import { businessDetailReducer } from 'ducks/apiBusinessDetail'
import { locationApiReducer } from 'ducks/locationApi'

export const rootReducer = combineReducers({
  user: userReducer,
  staticContent: staticReducer,
  businessApi: businessApiReducer,
  businessDetail: businessDetailReducer,
  locationApi: locationApiReducer,
})
