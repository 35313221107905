import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f8f2e7;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
`

export const Content = styled.div`
  /* width: 90%;
  margin: auto; */
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 21.1304px;
  line-height: 29px;
  color: #062a20;
  margin-bottom: 1rem;
  padding-left: 2rem;
  display: flex;
  flex: 0.3;
  align-items: flex-end;
  margin-top: 2%;
`

export const CardContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100vw;
  overflow-y: hidden;
  padding: 1rem;
  flex: 1;
  align-items: flex-start;
  overflow-x: scroll;
`

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(218, 205, 182, 0.41);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 135px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition-property: box-shadow, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  margin: 1rem 0;
  &:hover {
    box-shadow: -2px 2px 9px 4px rgba(6, 42, 32, 0.16);
    transform: translate(0, -0.3px);
  }
  &:not(:first-child),
  &:not(:last-child) {
    margin: 0 1rem;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
  padding: 0 1rem;
  box-sizing: border-box;
`

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
`

export const IconContainer = styled.div``

export const Icon = styled.img`
  height: auto;
  width: 60px;
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 15rem;
`

export const CardTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 21.7297px;
  line-height: 21px;
  color: #062a20;
  width: 70%;
  margin-bottom: 5px;
`

export const Distance = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #8f7e60;
`

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

export const Hours = styled.div``

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  // margin-bottom: 1rem;
  align-items: center;
  box-sizing: border-box;
`

export const CallButton = styled.a`
  border: 1.5px solid #ad9f86;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 0 1rem;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  text-align: center;
  color: #ad9f86;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

export const DirectionsButton = styled.a`
  background: #ad9f86;
  border-radius: 18px;
  padding: 0 1rem;
  height: 36px;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  color: #fffbf4;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

export const SeeMoreButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  justify-content: center;
  box-sizing: border-box;
`

export const IconVector = styled.img`
  height: 8px;
  // margin-left: 1px;
`

export const SeeMore = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 19px;
  letter-spacing: 0.240724px;
  color: #6fb750;
  width: 5rem;
`

export const IconCall = styled.img`
  height: 16px;
  margin-right: 7px;
`

export const IconLocation = styled.img`
  height: 14px;
  margin-right: 7px;
`

export const RatingValueNumber = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 15.29px;
  line-height: 37px;
  letter-spacing: 0.464749px;
  color: #062a20;
  margin-left: 3px;
`

export const Schedule = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #6fb750;
  width: 300px;
  // margin-top: 10px;
  margin-left: 5px;
`

export const Time = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #ad9f86;
  display: inline;
`

export const Line = styled.span`
  border-left: 1px solid #ad9f86;
  height: 24px;
  align-self: center;
  margin-left: 10px;
  // margin-right: 10px;
`

export const IconCopy = styled.img`
  height: 15px;
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
`
export const DirectionButton = styled.a`
  background: #ad9f86;
  border-radius: 30px;
  width: 115px;
  height: 36px;
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  color: #fffbf4;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

export const DirectionButtonDisabled = styled.a`
  background: #ad9f86;
  border-radius: 30px;
  width: 115px;
  height: 36px;
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  color: #fffbf4;
  pointer-events: none;
  filter: opacity(37%);
  // background-color: grey;
`
/* export const CallButton = styled.a`
  border: 1.5px solid #ad9f86;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  width: 115px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  text-align: center;
  color: #ad9f86;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
` */

export const CallButtonDisabled = styled.a`
  border: 1.5px solid #ad9f86;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  width: 115px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  text-align: center;
  color: #ad9f86;
  pointer-events: none;
  filter: opacity(37%);
  // background-color: grey;
`

export const ScheduleClosed = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #df2c1f;
  width: 300px;
  // margin-top: 10px;
`
