import { createContext, Dispatch, SetStateAction, useState } from 'react'

export const initialData = {
  center: {
    lat: 0,
    lng: 0,
  },
  zoom: 11,
  startIndex: 0,
  markerHoverId: '',
  scrollId: '',
}

type InitialData = typeof initialData

export const googleMapsContext = createContext<[InitialData, Dispatch<SetStateAction<InitialData>>]>([
  initialData,
  () => {},
])

export const useGoogleMapsContext = () => {
  const ctx = useState(initialData)

  return ctx
}
