import axios from 'axios'
import { setIp, setLocation } from 'ducks/locationApi'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useLocation = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setLocation({ location: { locationLat: position.coords.latitude, locationLng: position.coords.longitude } })
        )
      },
      () => {
        axios.get(`https://api.ipapi.com/check?access_key=${process.env.REACT_APP_IPAPI_KEY}`).then((data) => {
          dispatch(setIp({ api: { apiLat: data.data.latitude, apiLng: data.data.longitude } }))
        })
      }
    )
  }, [dispatch])
}
