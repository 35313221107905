import { FC } from 'react'
import { useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
import { Banner } from 'Views/Components/HomeBanner'

import { Header } from 'Views/Components/Header/index.'
import { LoaderPage } from 'Views/Components/LoaderPage'
import { Container } from './styled'

const Home: FC = () => {
  const state = useSelector(staticSelector)

  // console.log('window.location.host:', window.location.host)

  return (
    <>
      {state.strapi?.directoryHome ? (
        <Container>
          <Header />
          <Banner />
          {/* <div style={{ backgroundColor: '#FFFBF4' }}>
            <Footer />
          </div> */}
        </Container>
      ) : (
        <LoaderPage />
      )}
    </>
  )
}

export default Home
