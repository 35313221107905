import styled from 'styled-components'

export const Container = styled.div`
  // width: 100%;
  // top: 0;
  min-height: 600px;
  background-color: #fffbf4;
  display: flex;
  align-content: center;
  // position: absolute;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 400px;

  @media screen and (max-width: 1086px) {
    flex-direction: column;
    margin-top: 4rem;
  }
`

export const Message = styled.h2`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const Logo = styled.img`
  max-height: 174px;

  /* @media screen and (max-width: 1086px) {
    max-height: 150px;
  } */
  @media screen and (max-width: 722px) {
    max-height: 100px;
  }

  @media screen and (max-width: 526px) {
    max-height: 90px;
  }
`

export const Image = styled.img`
  max-height: 410px;
  // max-width: 600px;

  /*  @media screen and (max-width: 1086px) {
    max-height: 300px;
  } */

  @media screen and (max-width: 722px) {
    max-height: 300px;
  }

  @media screen and (max-width: 526px) {
    max-height: 200px;
  }

  @media screen and (max-width: 358px) {
    max-height: 180px;
  }
`

export const ImageContainer = styled.div``

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`
