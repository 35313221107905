import { FC } from 'react'

export const FilterIcon: FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2014_7961)">
      <path
        d="M2.12496 14.1665L5.66663 14.1665"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66663 12.0415L5.66663 16.2915"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.12504 2.8335L7.08337 2.8335"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08337 0.708496L7.08337 4.9585"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.125 8.5L8.5 8.5"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 6.375L11.3334 10.625"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 14.1665L14.875 14.1665"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 8.5L14.875 8.5"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91667 2.8335L14.875 2.8335"
        stroke="#062A20"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2014_7961">
        <rect width="17" height="17" fill="white" transform="translate(17) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
)
