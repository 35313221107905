import styled, { keyframes } from 'styled-components'
import LogoPNG from 'assets/loaderGreenBiz.png'

export const Container = styled.div`
  width: 100%;
  height: 63vh;
  background-color: #fffbf4;
  display: flex;
  align-items: center;
  justify-content: center;
`

const glowing = keyframes`
  0% { opacity: 0.7; transform: scale(0.9) }
  50% { opacity: 1; transform: scale(1);  }
  100% { opacity: 0.7; transform: scale(0.9) }
`

export const Logo = styled.img`
  width: 100px;
  height: 100px;
  animation: ${glowing} 1.5s ease-in-out infinite forwards;
`

Logo.defaultProps = {
  src: LogoPNG,
}

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  background-color: rgba(235, 182, 47, 0.25);
  border-radius: 17px;
  margin-bottom: 300px;
`

export const TextNotFound = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(16px, 2vw, 23.6178px);
  line-height: 29px;
  color: #07231d;
`
