import { createSlice } from '@reduxjs/toolkit'
// import { createSagasTrigger } from 'lib/redux/sagas'

export const initialState = {
  business: [] as any[],
  loading: false,
  refresing: false,
  error: '',
}

export type InitialState = typeof initialState

const businessApiSlice = createSlice({
  name: 'businessApi',
  initialState,
  reducers: {
    setBusinessApi(state, action) {
      state.business = action.payload
    },
    appendBusinessApi(state, action) {
      // this is to prevent duplicated items
      if (state.business.findIndex((i: any) => i.id === action.payload[0].id) === -1) {
        state.business.push(...action.payload)
      }
    },
    resetBusinessApi(state) {
      state.business = []
      state.error = ''
    },
    request(state, action) {
      if (action.payload.refresh) {
        state.refresing = true
      } else {
        state.loading = true
      }
    },
    successful(state, action) {
      state.business = action.payload
    },
    failure(state, action) {
      state.error = action.payload
    },
    fulfill(state, action) {
      if (action.payload.refresh) {
        state.refresing = false
      } else {
        state.loading = false
      }
    },
  },
})

const createSagasTrigger = <T>(actionType: string) => ({
  trigger: actionType,
  run: (payload: T) => ({
    type: actionType,
    payload,
  }),
})

export const businessSagas = createSagasTrigger<{ query: any; refresh?: boolean }>('business_data_sagas')

export const { setBusinessApi, resetBusinessApi, request, failure, fulfill, successful, appendBusinessApi } =
  businessApiSlice.actions

export const businessApiReducer = businessApiSlice.reducer
