import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  background-color: #153e7c;
  height: 197px;
  align-items: flex-end;
  display: flex;
  @media screen and (max-width: 1028px) {
    align-items: center;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    padding: 1rem 0;
    flex-direction: column;
    align-items: center;
  }
`

export const Logo = styled.div`
  margin-left: 46px;
  margin-top: 18px;
`

export const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 3%;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(16px, 3vw, 38px);
  line-height: 110%;
  color: #fffbf4;
  min-width: 5rem;
  width: 20vw;
  max-width: 12rem;
  font-size: 32px;
`

export const Image = styled.img`
  width: 192px;
  height: 104px;
  padding-bottom: 10px;
`
export const Separador = styled.span`
  border-left: 0.7px solid #ffffff;
  height: 20px;
  align-self: center;
  margin-left: 0px;
  margin-right: 30px;
`

export const ContainerSearchBar = styled.div`
  display: flex;
  flex-direction: column;
  // height: 160px;
  @media screen and (max-width: 1028px) {
    height: 0px;
  }
  @media screen and (max-width: 768px) {
    height: 50px;
  }
`
export const SearchBar = styled.div`
  display: flex;
  flex: 1;
  max-width: 1000px;
  & > div {
    flex: 1;
    & > div {
      max-width: 900px;
      @media screen and (max-width: 1000px) {
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    max-width: none;
    width: 100%;
  }
`
export const InputSearch = styled.div`
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  border: 2.5px solid #ffffff;
  flex-direction: row;
  display: flex;
  align-items: center;
  height: 35px;
  width: 50%;
  background: transparent;
  height: auto;
`
export const IconSearch = styled.div`
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`
export const InputTextSearch = styled.input`
  background-color: transparent;
  width: 350px;
  height: 28px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border: 0px;
  margin-left: 10px;
  color: white;
  outline: none;
  &::placeholder {
    color: white;
  }
`
export const ZipcodeSearch = styled.div`
  border: 2.5px solid #ffffff;
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: #eff3e5;
  width: 446px;
`
export const IconZipCode = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #eff3e5;
  margin-left: 10px;
`
export const ZipcodeText = styled.input`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background-color: #eff3e5;
  color: #062a20;
  padding-left: 10px;
  height: 28px;
  border-width: 0px;
  color: black;
  outline: none;

  &::placeholder {
    color: #062a20;
  }
`

export const SearchButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  background-color: #6fb750;
  border: 2.5px solid #ffffff;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
  color: #ffffff;
  border-top-color: white;
  border-bottom-color: white;
  border-right-color: white;
  border-left-color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #ffffff;
`
export const Categorys = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  @media screen and (max-width: 1028px) {
    display: none;
  }
`
export const InnerCategory = styled.div`
  margin-top: 10px;
  overflow-x: auto;
  max-width: 40vw;
  max-height: 11vh;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
`
export const CategoryTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  color: #fffbf4;
`
export const CategoryIcon = styled.div`
  margin-bottom: 10%;
`
export const CategoryButton = styled.div`
  width: 60px;
  height: 5vh;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.active {
    background: #6fb750;
    border-radius: 15px;
    padding: 10px;
    width: 60px;
    height: 7vh;
  }
`

export const CategoryContainer = styled.div`
  align-self: stretch;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  &:not(:last-child):after {
    content: '';
    height: 50px;
    width: 2px;
    background-color: #8b8b8b;
    margin: 0 1rem;
  }
  @media screen and (max-width: 434px) {
    &:after {
      content: '';
      height: 50px;
      width: 2px;
      background-color: #8b8b8b;
      margin: 0 1rem;
    }
  }
`
export const FilterRow = styled.div`
  display: flex;
  align-self: stretch;
  padding: 0.5rem 1rem 0 1rem;
`
export const FilterButton = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  background-color: #ebb62f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const FilterBottomPanel = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  height: 85vh;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  animation: ${slideIn} 0.2s ease-out forwards;
`
export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  border: none;
  padding: 1rem;
  cursor: pointer;
  background: none;
  outline: none;
  border-bottom: 1px solid #dacdb6;
  box-sizing: border-box;
  margin: 0 2rem;

  &::after {
    content: '';
    width: 3rem;
    height: 0.5rem;
    background-color: #dacdb6;
    border-radius: 0.25rem;
  }
`

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
`

export const ScrollTags = styled.div`
  flex: 1;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
`

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffbf4;
  border: solid 1px #ebb62f;
  border-radius: 0.725rem;
  padding: 0 0.5rem;
  height: 1.5rem;
  margin: 0.5rem;
  font-size: 0.8rem;

  & svg {
    cursor: pointer;
    margin-left: 0.5rem;
    margin-top: 3px;
  }
  & > span {
    line-height: 100%;
    white-space: nowrap;
  }
  &:hover {
    border-color: #000;
    & path {
      fill: #000;
    }
  }
`
export const CloseTag = styled.div``
