import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 776px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 34px;
  text-align: center;
  margin: 1rem 0;
  color: #ffffff;
`
export const Separador = styled.span`
  border-left: 0.7px solid #ffffff;
  height: 20px;
  align-self: center;
  margin-left: 27px;
  margin-right: 30px;
`
export const SearchBar = styled.div`
  margin-top: 2%;
  flex-direction: row;
  display: flex;
`
export const InputSearch = styled.div`
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  border: 2.5px solid #ffffff;
  flex-direction: row;
  display: flex;
  align-items: center;
  height: auto;
  width: 446px;
`
export const IconSearch = styled.div`
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`
export const InputTextSearch = styled.input`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.3375px;
  color: #ffffff;
  background-color: transparent;
  width: 350px;
  height: 28px;
  border: 0px;
  margin-left: 10px;
  color: white;
  outline: none;
  &::placeholder {
    color: white;
  }
`
export const ZipcodeSearch = styled.div`
  border: 2.5px solid #ffffff;
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: #eff3e5;
  width: 400px;
`
export const IconZipCode = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #eff3e5;
  margin-left: 10px;
`

export const LocationIcon = styled.img`
  height: 24px;
`

export const SearchIcon = styled.img`
  height: 18px;
`

export const ZipcodeText = styled.input`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.3375px;
  color: #062a20;
  background-color: #ffffff;
  padding-left: 10px;
  height: 28px;
  border-width: 0px;
  outline: none;
  &::placeholder {
    color: #062a20;
  }
`

export const SearchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  background-color: #6fb750;
  border: 2.5px solid #ffffff;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
  color: #ffffff;
  border-top-color: white;
  border-bottom-color: white;
  border-right-color: white;
  border-left-color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #ffffff;
  cursor: pointer;
`

export const InnerContainer = styled.div`
  /* opacity: 0.6; */
  background-color: RGBA(6, 20, 25, 0.3);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const SearchContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25%;
`

export const Categorys = styled.div`
  /* opacity: 0.6; */
  background-color: #153e7c;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`
export const InnerCategory = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
`
export const CategoryTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  /* identical to box height, or 79% */
  text-align: center;
  color: #fffbf4;
`
export const CategoryIcon = styled.div`
  margin-bottom: 10%;
`
export const CategoryButton = styled.div`
  /* background: #07231d;
  border: 1.5px solid #07231d;
  box-sizing: border-box;
  border-radius: 48px;
  box-shadow: 10px 10px 6px -7px rgba(5, 33, 27, 0.88);
  -webkit-box-shadow: 10px 10px 6px -7px rgba(5, 33, 27, 0.88);
  -moz-box-shadow: 10px 10px 6px -7px rgba(5, 33, 27, 0.88);
  &:hover {
    background-color: white;
    & ${CategoryTitle} {
      color: #07231d;
    }
    & ${CategoryIcon} {
      filter: brightness(0);
    }
  } */
  width: 100px;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const CategoryContainer = styled.div`
  align-self: stretch;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  &:not(:last-child):after {
    content: '';
    height: 50px;
    width: 2px;
    background-color: #8b8b8b;
    margin: 0 1rem;
  }
  @media screen and (max-width: 434px) {
    &:after {
      content: '';
      height: 50px;
      width: 2px;
      background-color: #8b8b8b;
      margin: 0 1rem;
    }
  }
`

export const SeparadorCategoria = styled.div`
  border: 0.7px solid #8b8b8b;
  height: 50px;
  margin-left: 20px;
  display: flex;
  align-items: 'center';
  justify-content: 'center';
`
