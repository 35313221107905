import { SagaIterator } from '@redux-saga/core'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { getBusinessByQuery } from 'services/apiService/getBusiness'
import { businessSagas, request, failure, fulfill, setBusinessApi, appendBusinessApi } from '../reducers'

function* getBusinessSagas(action: any): SagaIterator {
  const {
    payload: { query, refresh },
  } = action

  yield put(request({ refresh }))
  if (!parseInt(query.category)) {
    delete query.category
  }
  const { data } = yield call(getBusinessByQuery, query)
  if (data?.data?.data?.length > 0) {
    if (!refresh) {
      yield put(setBusinessApi(data.data.data))
    } else {
      yield put(appendBusinessApi(data.data.data))
    }
  } else {
    if (!refresh) {
      yield put(failure('There are no results'))
    }
  }

  yield put(fulfill({ refresh }))
}

export function* businessApiSagas(): SagaIterator {
  yield all([takeLatest(businessSagas.trigger, getBusinessSagas)])
}
