import { FC } from 'react'
import { Checkbox } from 'pretty-checkbox-react'
import * as S from './styled'

interface Props {
  options: any[]
  value: string[]
  onChange: (value: string[]) => void
  uniqueKey?: string
  labelKey?: string
}

export const MultipleCheckbox: FC<Props> = ({ options, value = [], onChange, uniqueKey = 'id', labelKey = 'name' }) => {
  const handleClear = () => {
    onChange([])
  }

  const handleOnChange = (index: string) => () => {
    const newCategories = Array.isArray(value) ? [...value] : `${value || ''}`.split('-')

    if (newCategories.includes(index)) {
      newCategories.splice(newCategories.indexOf(index), 1)
    } else {
      newCategories.push(index)
    }
    onChange(newCategories)
  }

  return (
    <S.Container>
      <S.ButtonContainer>
        <S.ClearButton onClick={handleClear}>Clear All</S.ClearButton>
      </S.ButtonContainer>
      {options.map((item: any) => {
        return (
          <S.CategoryDiv onClick={handleOnChange(item[uniqueKey])}>
            <Checkbox required checked={value.includes(item[uniqueKey])} />
            <S.CategoryOption>{item[labelKey]}</S.CategoryOption>
          </S.CategoryDiv>
        )
      })}
    </S.Container>
  )
}
