export const parseQueryValues = (obj: any) => {
  const newObj: any = {}
  Object.keys(obj).forEach((key: any) => {
    if (obj[key]) {
      switch (key) {
        case 'lat':
        case 'lng':
          newObj[key] = Number(obj[key] || 0)
          break
        default:
          newObj[key] = obj[key]
          break
      }
    }
  })

  return newObj
}
