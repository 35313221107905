import { FC, useEffect, useState } from 'react'
import Rating from '@mui/material/Rating'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { apiServiceDetail } from 'services/apiDetailBusiness'
import ReactTooltip from 'react-tooltip'
import globe from 'assets/globeIcon.png'
import insta from 'assets/instaIcon.png'
import star from 'assets/starIcon.png'
import { Helmet } from 'react-helmet'
import location from 'assets/location.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useSelector } from 'react-redux'
import { locationApiSelector } from 'ducks/locationApi'
import { Carousel } from 'react-responsive-carousel'
import { GoogleMapsComponentDetail } from '../GoogleMapsComponentDetails'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import fotoPrueba from '../../../assets/captura.png'
import iconPrueba from '../../../assets/iconPrueba.png'
import ArrowBackIcon from '../../../assets/backarrow_directory.png'
import iconCall from '../../../assets/iconCall.png'
import iconMail from '../../../assets/iconMail.png'
import iconCopy from '../../../assets/iconCopy.png'
import iconShare from '../../../assets/shareIcon.png'
import shareCopy from '../../../assets/shareCopy.png'
import shareFacebook from '../../../assets/shareFacebook.png'
import shareLinkedin from '../../../assets/shareLinkedin.png'
import { Loader } from '../Loader'
import innovator from '../../../assets/Innovator.png'
import participant from '../../../assets/participant.png'
import certified from '../../../assets/certified.png'
import { CarouselContainer } from '../SearchBody/styled'
import {
  Container,
  TopSection,
  IconCard,
  PrincipalInfo,
  Name,
  Distance,
  Schedule,
  InfoSection,
  ButtonSection,
  CardDetail,
  CardDetailContainer,
  CardPhoto,
  ButtonOptions,
  EmailShareButtonDetail,
  AboutTitle,
  AboutDetail,
  WhyGreen,
  WhyGreenDetail,
  RatingValueNumber,
  ArrowBack,
  Card,
  ArrowBackContainer,
  InfoContainer,
  BackIcon,
  TopContainer,
  Photo,
  IconCall,
  IconMail,
  IconShare,
  Adress,
  HoursContainer,
  Hours,
  DaysContainer,
  Days,
  SectionContainer,
  IconContainer,
  Icon,
  Circle,
  Circle2,
  Circle2Blurred,
  CircleBlurred,
  Logo,
  Closed,
  LogoDefault,
  ShareButtonDisabled,
  IconCopy,
  Line,
  IconMulti,
  TextShare,
  ShareContainer,
  ButtonGo,
  AddressTitleContainer,
  IconLocation,
  ButtonGoDisabled,
  LogoBusines,
  LogoBusinessCircle,
  AboutSection,
  AboutContainer,
  WhyGreenContainer,
  AboutContainerInner,
  SecondAboutContainerInner,
  SecondAboutHours,
  AdressContainer,
  SocialIcons,
  TopInfo,
  ScheduleClosed,
  IconShareTwitter,
  IconTwitter,
} from './styled'
import { Data } from './details'
import './toolTipStyles.css'
import { getDistanceFromLatLng } from './getDistanceFromLatLng'

import { daysAndTime, daysAndTimeYelp, isOpen, isOpenYelp, openOrClosed, openOrClosedYelp } from './getHours'

const MySwal = withReactContent(Swal)

export const BussinesCardDetailComponent: FC = () => {
  // const business = useSelector(apiBusinessDetailSelector)

  const locator = useSelector(locationApiSelector)
  // const businesses = useSelector(businessApiSelector)
  // const history = useHistory()

  const params: any = useParams()
  const { id } = params

  const [business, setBusiness] = useState<Data>()

  useEffect(() => {
    apiServiceDetail(id).then((response) => {
      if (response) {
        setBusiness(response?.data?.data?.data)
      }
    })
  }, [id])

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const tzlookup = require('tz-lookup')

  const timezone = (lat: number, lng: number) => {
    const zone = tzlookup(lat, lng)
    return zone
  }

  const locationUser = () => {
    let userLocation = { lat: 0, lng: 0 }
    if (locator?.location?.location?.locationLat) {
      userLocation = { lat: locator.location.location.locationLat, lng: locator.location.location.locationLng }
    } else {
      userLocation = { lat: locator?.api?.api?.apiLat, lng: locator?.api?.api?.apiLng }
    }
    return userLocation
  }

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const daysYelp = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  const goBackArrow = () => {
    /*  if (businesses?.business?.length > 1) {
      return <BackIcon src={ArrowBackIcon} alt="arrow_back" onClick={() => history.goBack()} />
    } else { */
    return (
      <Link to="/search">
        <BackIcon src={ArrowBackIcon} alt="arrow_back" />
      </Link>
    )
    // }
  }

  const TwitterIcon = ({ color }: { color: string }) => {
    return (
      <svg viewBox="0 0 300 300.251" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
          fill={color}
        />
      </svg>
    )
  }

  const businessOpenHour = business?.google_data?.result?.opening_hours?.periods
  const firstBusinessOpenHour = business?.google_data?.result?.opening_hours
  return (
    <Container>
      <Helmet>
        <title>{business?.name || 'Greenbiz Tracker'}</title>
        <meta name="description" content={business?.description || 'Greenbiz tracker'} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={business?.name || 'Greenbiz Tracker'} />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:description" content={business?.description || 'A Recognized Green Business'} />
        <meta property="og:image" content={business?.image || business?.logo || fotoPrueba} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <link rel="icon" href={iconPrueba} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={business?.name || 'Greenbiz Tracker'} />
        <meta name="twitter:description" content={business?.description || 'A Recognized Green Business'} />
        <meta name="twitter:image" content={business?.image || business?.logo || iconPrueba} />
      </Helmet>
      {business?.name ? (
        <Card>
          <CardDetailContainer>
            <TopContainer>
              <ArrowBackContainer>
                <ArrowBack>{goBackArrow()}</ArrowBack>
              </ArrowBackContainer>
              <CardDetail>
                <InfoSection>
                  <TopSection>
                    <TopInfo>
                      <IconCard>
                        {business.logo && business.logo.length ? (
                          <Logo src={business?.logo} alt="icon" />
                        ) : (
                          <LogoDefault src={iconPrueba} alt="icon" />
                        )}
                      </IconCard>
                      <PrincipalInfo>
                        <Name>{business?.name}</Name>
                        {firstBusinessOpenHour &&
                        businessOpenHour &&
                        isOpen(
                          business?.google_data?.result?.opening_hours.periods,
                          timezone(business.lat, business.lng)
                        ) ? (
                          <Schedule>
                            Open now {openOrClosed(business.google_data?.result?.opening_hours.periods)}
                          </Schedule>
                        ) : business.google_data?.result?.opening_hours &&
                          businessOpenHour &&
                          !isOpen(
                            business?.google_data?.result?.opening_hours.periods,
                            timezone(business.lat, business.lng)
                          ) ? (
                          <ScheduleClosed>Closed</ScheduleClosed>
                        ) : business.yelp_data?.hours &&
                          isOpenYelp(business?.yelp_data?.hours[0].open, timezone(business.lat, business.lng)) ? (
                          <Schedule>Open now {openOrClosedYelp(business?.yelp_data?.hours[0].open)}</Schedule>
                        ) : business.yelp_data?.hours &&
                          !isOpenYelp(business?.yelp_data?.hours[0].open, timezone(business.lat, business.lng)) ? (
                          <ScheduleClosed>Closed</ScheduleClosed>
                        ) : null}
                        <Distance>
                          {getDistanceFromLatLng(business.lat, business.lng, locationUser().lat, locationUser().lng)}{' '}
                          miles away
                        </Distance>
                        <div style={{ alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                          {business?.google_data?.result && business.google_data.result.rating ? (
                            <>
                              <Rating name="read-only" value={business?.google_data.result.rating} readOnly />
                              <RatingValueNumber>{business?.google_data.result.rating}</RatingValueNumber>
                            </>
                          ) : business?.yelp_data && business.yelp_data.rating ? (
                            <>
                              <Rating name="read-only" value={business?.yelp_data.rating} readOnly />
                              <RatingValueNumber>{business?.yelp_data.rating}</RatingValueNumber>
                            </>
                          ) : null}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4%' }}>
                          {business && business.completedTiers
                            ? business?.completedTiers.map((item: any, index: any) => {
                                return (
                                  <LogoBusinessCircle
                                    key={`logobusiness${index}`}
                                    data-tip
                                    data-for={`description${index}${item.name}${item.tier}`}
                                  >
                                    {/* <LogoBusines src={item.logo} alt="logo tier" /> */}
                                    {item.tier === 3 ? <LogoBusines src={innovator} alt="logo tier " /> : null}
                                    {item.tier === 1 ? <LogoBusines src={participant} alt="logo tier" /> : null}
                                    {item.tier === 2 ? <LogoBusines src={certified} alt="logo tier" /> : null}
                                    <ReactTooltip
                                      id={`description${index}${item.name}${item.tier}`}
                                      place="bottom"
                                      effect="solid"
                                      backgroundColor="#3BB5A6"
                                      textColor="white"
                                      className="tool-tip"
                                    >
                                      {item.name}
                                    </ReactTooltip>
                                  </LogoBusinessCircle>
                                  // <LogoBusinessCircle>{}</LogoBusinessCircle>
                                )
                              })
                            : null}
                        </div>
                      </PrincipalInfo>
                    </TopInfo>
                  </TopSection>
                  <ButtonSection></ButtonSection>
                </InfoSection>
              </CardDetail>
              <CardPhoto>
                <CarouselContainer>
                  {/* <ImgWrapper> */}
                  <Carousel
                    infiniteLoop
                    interval={3000}
                    showStatus={false}
                    showArrows={true}
                    showIndicators={false}
                    stopOnHover={false}
                    emulateTouch
                    centerMode
                    showThumbs={false}
                    autoFocus
                    centerSlidePercentage={100}
                  >
                    {business?.image && business?.image.length > 1
                      ? business?.image.map((itemImage: any) => (
                          <div style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                            <Photo src={itemImage} alt={business?.name} />
                          </div>
                        ))
                      : null}
                  </Carousel>
                  {business.image && business.image.length === 1 ? (
                    <div style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                      <Photo src={business.image[0]} alt={business?.name} />
                    </div>
                  ) : null}
                  {!business.image ? (
                    <div style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                      <Photo src={fotoPrueba} alt={business?.name} />
                    </div>
                  ) : null}
                  {/*  </ImgWrapper> */}
                </CarouselContainer>
              </CardPhoto>
            </TopContainer>
            <InfoContainer>
              <div style={{ marginTop: '2%', paddingLeft: '2%' }}>
                <ButtonOptions>
                  {business?.phone && business?.phone.length > 2 ? (
                    <>
                      <EmailShareButtonDetail href={`tel:${business?.phone}`} data-tip data-for="directions1">
                        <IconCall url={iconCall} />
                        Call
                      </EmailShareButtonDetail>
                      <ReactTooltip
                        id="directions1"
                        place="top"
                        effect="solid"
                        backgroundColor="white"
                        textColor="#6FB750"
                        border={true}
                        borderColor="#6FB750"
                        clickable={true}
                        delayHide={200}
                        className="tool-tip"
                      >
                        {business?.phone}
                        <Line />
                        <IconCopy
                          onClick={() => {
                            navigator.clipboard.writeText(business?.phone)
                            MySwal.fire({
                              title: 'Copied to clipboard',
                              showConfirmButton: false,
                              width: 400,
                              background: '#fffbf4',
                              timer: 1000,
                              customClass: {
                                container: 'swal-container',
                                title: 'swal-title',
                              },
                            })
                          }}
                          src={iconCopy}
                        />
                      </ReactTooltip>
                    </>
                  ) : (
                    <ShareButtonDisabled>
                      <IconMail url={iconCall} />
                      Call
                    </ShareButtonDisabled>
                  )}
                  {business?.email && business?.email.length > 2 ? (
                    <>
                      <EmailShareButtonDetail href={`mailto:${business?.email}`} data-tip data-for="directions2">
                        <IconMail url={iconMail} />
                        Email
                      </EmailShareButtonDetail>

                      <ReactTooltip
                        id="directions2"
                        place="top"
                        effect="solid"
                        backgroundColor="white"
                        textColor="#6FB750"
                        border={true}
                        borderColor="#6FB750"
                        clickable={true}
                        delayHide={200}
                        className="tool-tip"
                      >
                        {business?.email}
                        <Line />
                        <IconCopy
                          onClick={() => {
                            navigator.clipboard.writeText(business?.email)
                            MySwal.fire({
                              title: 'Copied to clipboard',
                              showConfirmButton: false,
                              width: 400,
                              background: '#fffbf4',
                              timer: 1000,
                              customClass: {
                                container: 'swal-container',
                                title: 'swal-title',
                              },
                            })
                          }}
                          src={iconCopy}
                        />
                      </ReactTooltip>
                    </>
                  ) : (
                    <ShareButtonDisabled>
                      <IconMail url={iconMail} />
                      Email
                    </ShareButtonDisabled>
                  )}

                  <EmailShareButtonDetail data-tip data-for="sharebutton">
                    <IconShare url={iconShare} />
                    Share
                  </EmailShareButtonDetail>
                  <ReactTooltip
                    id="sharebutton"
                    place="right"
                    effect="solid"
                    backgroundColor="white"
                    textColor="#6FB750"
                    border={true}
                    borderColor="#6FB750"
                    clickable={true}
                    delayHide={200}
                    className="tool-tip"
                  >
                    <ShareContainer
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href)
                        MySwal.fire({
                          title: 'Copied to clipboard',
                          showConfirmButton: false,
                          width: 400,
                          background: '#fffbf4',
                          timer: 1000,
                          customClass: {
                            container: 'swal-container',
                            title: 'swal-title',
                          },
                        })
                      }}
                    >
                      <IconMulti src={shareCopy} /> <TextShare>Copy link</TextShare>
                    </ShareContainer>
                    <ShareContainer
                      onClick={() => {
                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
                      }}
                    >
                      <IconMulti src={shareFacebook} /> <TextShare>Facebook</TextShare>
                    </ShareContainer>
                    <ShareContainer
                      onClick={() => {
                        window.open(`https://www.linkedin.com/share?url=${window.location.href}`)
                      }}
                    >
                      <IconMulti src={shareLinkedin} /> <TextShare>Linkedin</TextShare>
                    </ShareContainer>
                    <ShareContainer
                      onClick={() => {
                        window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`)
                      }}
                    >
                      <IconShareTwitter>
                        <TwitterIcon color="green" />
                      </IconShareTwitter>
                      <TextShare>X</TextShare>
                    </ShareContainer>
                  </ReactTooltip>
                </ButtonOptions>
              </div>
              <AboutSection>
                {business?.description || business?.how_green ? (
                  <AboutContainer>
                    {business?.description ? (
                      <AboutContainerInner>
                        <AboutTitle>About</AboutTitle>
                        <AboutDetail>{business?.description}</AboutDetail>
                      </AboutContainerInner>
                    ) : null}
                    {business?.how_green ? (
                      <WhyGreenContainer>
                        <WhyGreen>Why we're Green</WhyGreen>
                        <WhyGreenDetail>{business?.how_green}</WhyGreenDetail>
                      </WhyGreenContainer>
                    ) : null}
                  </AboutContainer>
                ) : null}
                <SecondAboutContainerInner>
                  {(business?.google_data?.result &&
                    business?.google_data?.result?.opening_hours?.periods?.length > 7) ||
                  (business?.yelp_data?.hours && business?.yelp_data?.hours[0]?.open?.length > 7) ? null : business
                      ?.google_data?.result &&
                    !!business?.google_data?.result?.opening_hours &&
                    business?.google_data?.result.opening_hours.periods.length === 1 &&
                    !business?.google_data?.result.opening_hours.periods[0].close ? (
                    <SecondAboutHours>
                      <AboutTitle>Hours</AboutTitle>
                      <SectionContainer>
                        <DaysContainer>
                          {days.map((item: string, index: number) => {
                            return <Days key={`day-list-${index}`}>{item}</Days>
                          })}
                        </DaysContainer>
                        <HoursContainer>
                          {days.map(() => {
                            return <Hours>12:00 - 12:00</Hours>
                          })}
                        </HoursContainer>
                      </SectionContainer>
                    </SecondAboutHours>
                  ) : !!business?.google_data?.result?.opening_hours &&
                    business?.google_data?.result.opening_hours.periods.length > 2 ? (
                    <SecondAboutHours>
                      <AboutTitle>Hours</AboutTitle>
                      <SectionContainer>
                        <DaysContainer>
                          {days.map((item: string, index: number) => {
                            return <Days key={`day-list-${index}`}>{item}</Days>
                          })}
                        </DaysContainer>
                        <HoursContainer>
                          {business.google_data.result.opening_hours.periods &&
                            daysAndTime(business.google_data.result.opening_hours.periods).map(
                              (item: any, index: number) => {
                                if (item?.close?.flag) {
                                  return <Closed key={`closed-${index}`}>Closed</Closed>
                                }
                                return (
                                  <Hours key={`open-hours-${index}`}>
                                    {`${new Date(
                                      '1970-01-01T' +
                                        item?.open?.time.substring(0, 2) +
                                        ':' +
                                        item?.open?.time.substring(2) +
                                        'Z'
                                    ).toLocaleTimeString('en-US', {
                                      timeZone: 'UTC',
                                      hour12: true,
                                      hour: 'numeric',
                                      minute: 'numeric',
                                    })} - ${new Date(
                                      '1970-01-01T' +
                                        item?.close?.time.substring(0, 2) +
                                        ':' +
                                        item?.close?.time.substring(2) +
                                        'Z'
                                    ).toLocaleTimeString('en-US', {
                                      timeZone: 'UTC',
                                      hour12: true,
                                      hour: 'numeric',
                                      minute: 'numeric',
                                    })}`}
                                  </Hours>
                                )
                              }
                            )}
                        </HoursContainer>
                      </SectionContainer>
                    </SecondAboutHours>
                  ) : business?.yelp_data?.hours && business?.yelp_data?.hours[0].open ? (
                    <SecondAboutHours>
                      <AboutTitle>Hours</AboutTitle>
                      <SectionContainer>
                        <DaysContainer>
                          {daysYelp.map((item: string, index: number) => {
                            return <Days key={`day-yelp-list-${index}`}>{item}</Days>
                          })}
                        </DaysContainer>
                        <HoursContainer>
                          {business?.yelp_data?.hours[0].open &&
                            daysAndTimeYelp(business?.yelp_data?.hours[0].open).map((item: any, index: number) => {
                              if (item.flag) {
                                return <Closed key={`closed-yelp-${index}`}>Closed</Closed>
                              }
                              return (
                                <Hours key={`open-hours-yelp${index}`}>
                                  {`${new Date(
                                    '1970-01-01T' + item?.start.substring(0, 2) + ':' + item?.start.substring(2) + 'Z'
                                  ).toLocaleTimeString('en-US', {
                                    timeZone: 'UTC',
                                    hour12: true,
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })} - ${new Date(
                                    '1970-01-01T' + item?.end.substring(0, 2) + ':' + item?.end.substring(2) + 'Z'
                                  ).toLocaleTimeString('en-US', {
                                    timeZone: 'UTC',
                                    hour12: true,
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })}`}
                                </Hours>
                              )
                            })}
                        </HoursContainer>
                      </SectionContainer>
                    </SecondAboutHours>
                  ) : null}
                  <AdressContainer>
                    {business?.google_data?.result?.place_id ? (
                      <AddressTitleContainer>
                        <AboutTitle>Address</AboutTitle>
                        {business.hide_address === 0 ? (
                          <ButtonGo
                            href={`https://www.google.com/maps/dir/?api=1&destination=1&destination_place_id=${business?.google_data?.result?.place_id}`}
                            target="_blank"
                          >
                            <IconLocation src={location} />
                            Go
                          </ButtonGo>
                        ) : (
                          <ButtonGoDisabled>
                            <IconLocation src={location} />
                            Go
                          </ButtonGoDisabled>
                        )}
                      </AddressTitleContainer>
                    ) : business?.google_place_id ? (
                      <AddressTitleContainer>
                        <AboutTitle>Address</AboutTitle>
                        {business.hide_address === 0 ? (
                          <ButtonGo
                            href={`https://www.google.com/maps/dir/?api=1&destination=1&destination_place_id=${business?.google_place_id}`}
                            target="_blank"
                          >
                            <IconLocation src={location} />
                            Go
                          </ButtonGo>
                        ) : (
                          <ButtonGoDisabled>
                            <IconLocation src={location} />
                            Go
                          </ButtonGoDisabled>
                        )}
                      </AddressTitleContainer>
                    ) : business?.street_address.length > 1 &&
                      business?.city.length > 1 &&
                      business?.state.length > 1 ? (
                      <AddressTitleContainer>
                        <AboutTitle>Address</AboutTitle>
                        {business.hide_address === 0 ? (
                          <ButtonGo
                            href={`https://www.google.com/maps/dir/?api=1&destination=${business.street_address.replace(
                              / /g,
                              '+'
                            )}+${business.city.replace(/ /g, '+')}+${business.state}`}
                            target="_blank"
                          >
                            <IconLocation src={location} />
                            Go
                          </ButtonGo>
                        ) : (
                          <ButtonGoDisabled>
                            <IconLocation src={location} />
                            Go
                          </ButtonGoDisabled>
                        )}
                      </AddressTitleContainer>
                    ) : business.lat && business.lng ? (
                      <AddressTitleContainer>
                        <AboutTitle>Address</AboutTitle>
                        {business.hide_address === 0 ? (
                          <ButtonGo
                            href={`https://www.google.com/maps/dir/?api=1&destination=${business.lat},${business.lng}`}
                            target="_blank"
                          >
                            <IconLocation src={location} />
                            Go
                          </ButtonGo>
                        ) : (
                          <ButtonGoDisabled>
                            <IconLocation src={location} />
                            Go
                          </ButtonGoDisabled>
                        )}
                      </AddressTitleContainer>
                    ) : (
                      <AddressTitleContainer>
                        <AboutTitle>Address</AboutTitle>
                        <ButtonGoDisabled>
                          <IconLocation src={location} />
                          Go
                        </ButtonGoDisabled>
                      </AddressTitleContainer>
                    )}
                    {business?.hide_address === 0 ? (
                      <div>
                        <Adress>{`${business?.street_address} , ${business?.city} ${business?.state}, ${business?.zip}`}</Adress>
                      </div>
                    ) : (
                      <Adress>{`${business?.city} ${business?.state} ${business?.zip}`}</Adress>
                    )}
                    <div style={{ height: '279px', width: '100%' }}>
                      <div style={{ marginTop: '5%', marginBottom: '10%' }}></div>
                      {business?.lat ? (
                        <GoogleMapsComponentDetail markers={business} hideAdress={business?.hide_address} />
                      ) : null}
                    </div>
                  </AdressContainer>
                  <SocialIcons>
                    <AboutTitle>Also find us here:</AboutTitle>
                    <IconContainer>
                      {business?.website ? (
                        <>
                          <Circle2 data-tip data-for="website icon" href={business?.website} target="_blank">
                            <Icon url={globe} />
                          </Circle2>
                          <ReactTooltip
                            id="website icon"
                            place="top"
                            effect="solid"
                            backgroundColor="grey"
                            textColor="#EFF3E5"
                            border={true}
                            borderColor="#EFF3E5"
                          >
                            Website
                          </ReactTooltip>
                        </>
                      ) : (
                        <Circle2Blurred>
                          <Icon url={globe} />
                        </Circle2Blurred>
                      )}

                      {business?.social_instagram ? (
                        <>
                          <Circle data-tip data-for="instagram icon" href={business?.social_instagram} target="_blank">
                            <Icon url={insta} />
                          </Circle>
                          <ReactTooltip
                            id="instagram icon"
                            place="top"
                            effect="solid"
                            backgroundColor="grey"
                            textColor="#EFF3E5"
                            border={true}
                            borderColor="#EFF3E5"
                          >
                            Instagram
                          </ReactTooltip>
                        </>
                      ) : (
                        <CircleBlurred>
                          <Icon url={insta} />
                        </CircleBlurred>
                      )}
                      {business?.social_twitter ? (
                        <>
                          <Circle2 data-tip data-for="twitter icon" href={business?.social_twitter} target="_blank">
                            <IconTwitter>
                              <TwitterIcon color="black" />
                            </IconTwitter>
                          </Circle2>
                          <ReactTooltip
                            id="twitter icon"
                            place="top"
                            effect="solid"
                            backgroundColor="grey"
                            textColor="#EFF3E5"
                            border={true}
                            borderColor="#EFF3E5"
                          >
                            X
                          </ReactTooltip>
                        </>
                      ) : (
                        <Circle2Blurred>
                          <IconTwitter>
                            <TwitterIcon color="black" />
                          </IconTwitter>
                        </Circle2Blurred>
                      )}
                      {business?.yelp_link && business?.yelp_link ? (
                        <>
                          <Circle data-tip data-for="yelp icon" href={business.yelp_link} target="_blank">
                            <Icon url={star} />
                          </Circle>
                          <ReactTooltip
                            id="yelp icon"
                            place="top"
                            effect="solid"
                            backgroundColor="grey"
                            textColor="#EFF3E5"
                            border={true}
                            borderColor="#EFF3E5"
                          >
                            Yelp
                          </ReactTooltip>
                        </>
                      ) : (
                        <CircleBlurred>
                          <Icon url={star} />
                        </CircleBlurred>
                      )}
                    </IconContainer>
                  </SocialIcons>
                </SecondAboutContainerInner>
              </AboutSection>
            </InfoContainer>
          </CardDetailContainer>
        </Card>
      ) : (
        <Loader />
      )}
    </Container>
  )
}
