import { useEffect, useMemo, useRef, useState, useContext } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { locationApiSelector } from 'ducks/locationApi'
import { resetBusinessApi, businessSagas, businessApiSelector } from 'ducks/businessApi'
import { categoriesSelector, staticSelector } from 'ducks/static'
import { slugsToIds } from 'lib/parsers/categories'
import { googleMapsContext } from 'Views/Components/GoogleMapsComponent/context'

export const parseValues = (obj: any) => {
  const newObj: any = {}
  Object.keys(obj).forEach((key: any) => {
    if (obj[key]) {
      switch (key) {
        case 'lat':
        case 'lng':
          newObj[key] = Number(obj[key] || 0)
          break
        default:
          newObj[key] = obj[key]
          break
      }
    }
  })

  return newObj
}

const businessLimit = 10

export const useQueryRecovery = () => {
  const locator = useSelector(locationApiSelector)
  const categories = useSelector(categoriesSelector)
  const businesses = useSelector(businessApiSelector)
  const [, setMapState] = useContext(googleMapsContext)

  const dispatch = useDispatch()

  const defaultValues = useMemo(() => {
    const q = window.location.search.substring(1)
    const queryObject: any = parseValues(queryString.parse(q))

    return typeof queryObject === 'object' ? queryObject : {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [valuesState, setValues] = useState({
    ...defaultValues,
    category: defaultValues.category ? `${defaultValues.category}`.split('-').filter((i: any) => !!i) : [],
  })

  useEffect(() => {
    const q = window.location.search.substring(1)
    const queryObject: any = parseValues(queryString.parse(q))

    setValues((v: any) => {
      const latitude = v.lat || locator?.location?.location?.locationLat || locator?.api?.api?.apiLat || 0
      const longitude = v.lng || locator?.location?.location?.locationLng || locator?.api?.api?.apiLng || 0
      return {
        ...queryObject,
        lat: latitude,
        lng: longitude,
      }
    })
  }, [setValues, categories, locator])

  const currentValues = useRef({ ...defaultValues })

  const handleSearch = (values: any) => {
    dispatch(resetBusinessApi())
    const latitude = values.lat || locator?.location?.location?.locationLat || locator?.api?.api?.apiLat || 0
    const longitude = values.lng || locator?.location?.location?.locationLng || locator?.api?.api?.apiLng || 0
    const ow = values.ow
    const query = {
      ...values,
      lat: latitude,
      lng: longitude,
      limit: businessLimit,
      radius: `${values.radius}`.match(/\d+/g)?.join(''),
      ...slugsToIds(values.category, categories),
      ...ow,
    }
    if (!parseInt(query.category)) {
      delete query.category
    }
    setValues({
      ...query,
      radius: values.radius,
      category: values.category,
      ...ow,
    })
    dispatch(businessSagas.run({ query }))
  }

  const handleQueryChange = (values: any, zoom?: number) => {
    setMapState((v) => ({ ...v, center: { lat: values.lat, lng: values.lng }, zoom: zoom || 11 }))
    const newValues = { ...currentValues.current, ...values }
    // this is to delete category filter when user enter a new keyword from text input search
    if (values && Object.keys(values).includes('keyword') && values.keyword !== currentValues.current.keyword) {
      delete newValues.category
    }
    const query = parseValues({
      ...newValues,
    })

    handleSearch(query)
    const queryStringResult = queryString.stringify(query)
    window.history.pushState(null, '', `?${queryStringResult}`)
    currentValues.current = { ...newValues }
  }

  const latitude = locator?.location?.location?.locationLat || locator?.api?.api?.apiLat || 0
  const longitude = locator?.location?.location?.locationLng || locator?.api?.api?.apiLng || 0

  const hasResult = businesses?.business?.length

  const firstRender = useRef(false)
  const state = useSelector(staticSelector)

  useEffect(() => {
    let stateObject: { lat: any; lng: any; zoom: any }
    const querystring = window.location.search
    const params = new URLSearchParams(querystring)
    const stateId = params.get('state_id')
    if (stateId && !firstRender.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // eslint-disable-next-line eqeqeq
      stateObject = state?.strapi?.nationalState?.stateInfo?.find((id: any) => id.stateNumberId == stateId)
      // eslint-disable-next-line no-console
      handleQueryChange({ lat: stateObject?.lat, lng: stateObject?.lng }, Number(stateObject?.zoom))
      firstRender.current = true
    } else if (latitude && longitude && !firstRender.current) {
      firstRender.current = true
      handleSearch(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, hasResult])

  return { defaultValues, handleQueryChange, currentValues: valuesState }
}
