import styled, { keyframes } from 'styled-components'

export const Title = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28.1739px;
  line-height: 29px;
  /* or 103% */

  color: #4d4d4d;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const CategoryTitle = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 21.1304px;
  line-height: 29px;
  /* or 137% */

  color: #4d4d4d;
`
export const CategoryOption = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16.5579px;
  line-height: 18px;
  max-width: 10rem;
  text-align: left;
  /* or 107% */

  letter-spacing: 0.265725px;

  color: #4d4d4d;
  margin-left: 5px;
`
export const Separador = styled.span`
  border-bottom: 1px solid #ecdebb;
  width: 175px;
  height: 0px;
  margin-right: 40px;
  margin-top: 20px;
`
export const Separador2 = styled.span`
  border-bottom: 1px solid red;
  width: 10;
  height: 100px;
  margin-right: 40px;
  margin-top: 20px;
`

export const FilterSection = styled.div`
  width: 15%;
  margin-left: 4%;
  margin-right: 3%;
  margin-top: 3%;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CarouselContainer = styled.div`
  //display: flex;
  //border-radius: 37px;
  display: flex;
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 0.5;
  align-self: auto;
  height: 100%;
  & > .carousel-root {
    display: flex;
    &:hover {
      border-radius: 37px;
    }
    object-fit: cover;
  }
  & .slider-wrapper {
    height: 100%;
  }
  & .slider-wrapper > .slider {
    height: 100%;
  }
  & .control-next {
    border-radius: 0px 37px 37px 0px;
  }
  & .control-prev {
    border-radius: 37px 0px 0px 37px;
  }
`

export const CardSection = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  margin-top: 2rem;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #fffbf4;
  &.o-column {
    flex-direction: column;
    & ${CardSection} {
      margin-top: 0;
    }
  }
`

const scaleUp = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
`

export const Card = styled.div`
  // width: 786px;
  // height: 247px;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(218, 205, 182, 0.41);
  border-radius: 30px;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  transition-property: box-shadow, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:hover {
    box-shadow: -1px 1px 6px 4px rgba(6, 42, 32, 0.16);
    transform: translate(0, -0.3px);
  }

  body::-webkit-scrollbar {
    width: 1em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: red;
    outline: 1px solid slategrey;
  }
`

export const CardContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 16px;
  height: 193px;
  box-sizing: border-box;
  display: flex;

  &.scaleUp ${Card} {
    animation: ${scaleUp} 0.5s ease-in-out;
    box-shadow: 0px 4px 10px rgba(218, 205, 182, 0.41);
  }

  &:first-child {
    padding-top: 8px;
  }
`
export const InfoSection = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: stretch;
  flex-basis: 0;
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 1rem;
`

export const PhotoSection = styled.div`
  display: flex;
  //overflow: hidden;
  flex-basis: 0;
  flex-grow: 0.5;
  align-self: stretch;

  @media screen and (max-width: 570px) {
    display: none;
  }
`

export const PhotoCard = styled.img`
  object-fit: cover;
  // display: block;
  width: 100%;
  height: 100%;
`

export const PhotoDefault = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
`

export const MapSection = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  padding: 2rem 2rem 0 2rem;
  box-sizing: border-box;
  border-radius: 30px;
  min-height: 300px;
`
export const CategoryDiv = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-align: center;
`
export const TopSection = styled.div`
  // padding: 2% 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconCard = styled.div``

export const Icon = styled.img`
  // aspect-ratio: 1;
  width: 3.5vw;

  @media screen and (max-width: 570px) {
    width: 9vw;
  }
`

export const PrincipalInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0 1rem;
  box-sizing: border-box;
  width: 500px;
`
export const Name = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(0.9rem, 2vw, 1.3rem);
  line-height: 21px;
  color: #062a20;
`

export const Distance = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(0.8rem, 1vw, 1rem);
  letter-spacing: 0.290603px;
  color: #8f7e60;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const KeyWordContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: 3%;
`

export const Keywords = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 199% */

  color: #409658;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #eff3e5;
  border-radius: 7.48468px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  width: 50px;
`
export const Schedule = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #6fb750;
  width: 300px;
  // margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
`

export const ScheduleClosed = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #df2c1f;
  // width: 300px;
`

export const Time = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.290603px;
  color: #ad9f86;
  display: inline;
`

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const CallButton = styled.a`
  border: 1.5px solid #ad9f86;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  text-align: center;
  color: #ad9f86;
  box-sizing: border-box;
  padding: 0 0.5rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }

  @media (max-width: 300px) {
    padding: 0 1rem;
  }
`

export const CallButtonDisabled = styled.a`
  border: 1.5px solid #ad9f86;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  text-align: center;
  color: #ad9f86;
  pointer-events: none;
  filter: opacity(37%);
  box-sizing: border-box;
  padding: 0 0.5rem;
  // background-color: grey;

  @media (max-width: 300px) {
    padding: 0 1rem;
  }
`

export const IconCall = styled.img`
  height: 16px;
  margin-right: 7px;
  @media (max-width: 300px) {
    display: none;
  }
`

export const IconLocation = styled.img`
  height: 14px;
  margin-right: 7px;
  @media (max-width: 300px) {
    display: none;
  }
`

export const DirectionButton = styled.a`
  background: #ad9f86;
  border-radius: 30px;
  height: 36px;
  margin-left: 0.5rem;
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  color: #fffbf4;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }

  @media (max-width: 300px) {
    padding: 0 1rem;
  }
`

export const DirectionButtonDisabled = styled.a`
  background: #ad9f86;
  border-radius: 30px;
  height: 36px;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 9px;
  color: #fffbf4;
  pointer-events: none;
  filter: opacity(37%);
  // background-color: grey;

  @media (max-width: 300px) {
    padding: 0 1rem;
  }
`

export const SeeMoreButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1390px) {
    display: none;
  }
`

export const SeeMore = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(0.7rem, 1vw, 1rem);
  line-height: 100%;
  letter-spacing: 0.240724px;
  color: #6fb750;
  padding-left: 7px;
`

export const IconVector = styled.img`
  height: 8px;
  // margin-left: 1px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 151px;
  width: 35vw;
  background-color: rgba(235, 182, 47, 0.25);
  border-radius: 17px;
`

export const TextNotFound = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 23.6178px;
  line-height: 29px;
  color: #07231d;
`
export const RatingValueNumber = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 15.29px;
  line-height: 37px;
  letter-spacing: 0.464749px;
  color: #062a20;
`

export const Line = styled.span`
  border-left: 1px solid #ad9f86;
  height: 24px;
  align-self: center;
  margin-left: 10px;
  // margin-right: 10px;
`

export const IconCopy = styled.img`
  height: 15px;
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
`

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  /* height: calc(100vh - 80px - 197px); */
  overflow: hidden;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    height: 100vh !important;
    position: relative;

    & ${MapSection} {
      flex-basis: initial;
      flex-grow: initial;
      padding: 0;
      border-radius: 0;
      height: 100vh;
      max-height: none;
    }
    & ${CardSection} {
      margin: 0;
      background: #fffbf4;
      height: 100vh;
      position: absolute;
      bottom: 0;
      top: 70%;
      left: 0;
      right: 0;
      z-index: 99;
      display: flex;
      border-radius: 1rem 1rem 0 0;
      flex-direction: column;
      box-shadow: 0px -5px 4px rgba(208, 208, 208, 0.25);
      transition: top 0.2s ease-out;
      &.o-is-open {
        top: 1rem;
      }
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    min-height: 800px;
    & ${MapSection} {
      min-height: 800px;
    }
    & ${CardSection} {
      min-height: 800px;
    }
  }
`

export const CardControl = styled.div`
  border-radius: 0.25rem;
  background: #dacdb6;
  align-self: center;
  margin: 1rem;
  cursor: pointer;
`

export const CardControlIcon = styled.div`
  cursor: pointer;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #dacdb6;
  box-sizing: border-box;
  margin: 0 1rem;
  & > span {
    &:first-child {
      width: 5rem;
      color: #409658;
      font-weight: bold;
    }
    &:nth-child(2) {
      width: 3rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      background: #dacdb6;
    }
    &:last-child {
      width: 5rem;
    }
  }
`
