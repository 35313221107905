import { FC } from 'react'
import GoogleMapReact from 'google-map-react'
import markerBusiness from '../../../assets/markerDisabled.png'

// eslint-disable-next-line no-empty-pattern
// eslint-disable-next-line prettier/prettier
// eslint-disable-next-line no-empty-pattern
const AnyReactComponent = ({}: any) => (
  <div>
    <img src={markerBusiness} alt="BusinessMarker" style={{ height: '28px', width: '28px', objectFit: 'scale-down' }} />
  </div>
)

export const GoogleMapsComponentDetail: FC<{ markers: any; hideAdress: number }> = ({ markers, hideAdress }) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define

  const { lat, lng } = markers

  const createMapOptions = () => {
    return {
      gestureHandling: hideAdress === 1 ? 'none' : 'greedy',
      disableDefaultUI: hideAdress === 0 ? false : true,
      fullscreenControl: hideAdress === 0 ? true : false,
      zoomControl: hideAdress === 0 ? true : false,
      styles: [
        {
          featureType: 'all',
          elementType: 'geometry.fill',
          stylers: [
            {
              weight: '2.00',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#9c9c9c',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              color: '#f2f2f2',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#7b7b7b',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: '#46bcec',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#c8d7d4',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#070707',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
      ],
    }
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100%', width: '100%', borderRadius: '30px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_ACCESS_KEY}` }}
        defaultCenter={{ lat, lng }}
        defaultZoom={11}
        options={createMapOptions}
      >
        {/* {hideAdress === 0 ? <h1> hola</h1> : <h2> hola</h2>} */}
        <AnyReactComponent lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  )
}
