import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState = {
  strapi: {},
}

export type InitialState = typeof initialState
type SetStatic = PayloadAction<any>

const staticSlice = createSlice({
  name: 'static',
  initialState,
  reducers: {
    setStatic(state, action: SetStatic) {
      state.strapi = action.payload
    },
  },
})

export const { setStatic } = staticSlice.actions

export const staticReducer = staticSlice.reducer
