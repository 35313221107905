import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
import { useMediaQuery } from '@mui/material'
import { SearchBarComponent } from '../SearchComponentBar'
import { FilterIcon } from '../icons/FilterIcon'
import { Filter } from '../SearchBody/Filter'
import { CancelIcon } from '../icons/Close'
import {
  Container,
  SearchBar,
  Title,
  TitleContainer,
  FilterRow,
  FilterButton,
  FilterBottomPanel,
  CloseButton,
  FilterColumn,
  ScrollTags,
  Tag,
  CloseTag,
  ContainerSearchBar,
} from './styled'
import './styles.css'
import { getTagItems } from './getTagsItems'
import { DeleteFilterItemFromValue } from './deleteFilterItemFromValue'
import { CategoryTitle, Categorys, InnerCategory, CategoryContainer, CategoryButton, CategoryIcon } from './styled'
const DEFAULT_ICON = 'https://gbn-national-site-api-bucket.s3.us-east-2.amazonaws.com/Category_Market_0120d9cd29.png'

interface Props {
  onQueryChange?: (searchValues: any) => void
  defaultValues?: any
  filterConfig?: any
  onChangeByPropName: (category: string, value: any) => void
}

export const SearchSearchBar: FC<Props> = ({ onQueryChange, defaultValues, filterConfig, onChangeByPropName }) => {
  const [showFilter, setFilter] = useState(false)
  const mobileMode = useMediaQuery('(max-width: 1000px)')
  const state = useSelector(staticSelector)
  const [activeCategory, setActiveCategory] = useState<string[]>([])

  const handleFilterClick = () => {
    setFilter(!showFilter)
  }
  const handleClick = (v: string) => () => {
    const updatedCategories = [...activeCategory]
    if (updatedCategories.includes(v)) {
      const index = updatedCategories.indexOf(v)
      updatedCategories.splice(index, 1)
    } else {
      updatedCategories.push(v)
    }
    onChangeByPropName('category', updatedCategories)
    setActiveCategory(updatedCategories)
  }
  const handleTagClick = (property: string, tagValue: string) => () => {
    if (filterConfig?.onSetFilters)
      filterConfig.onSetFilters(DeleteFilterItemFromValue(property, tagValue, filterConfig.values) || {})
  }

  return (
    <Container>
      {!mobileMode && (
        <TitleContainer>
          {state?.strapi?.directorySearch?.SearchBar?.title ? (
            <Title>{state.strapi.directorySearch.SearchBar.title}</Title>
          ) : null}
        </TitleContainer>
      )}
      <ContainerSearchBar>
        <SearchBar>
          <SearchBarComponent onQueryChange={onQueryChange} defaultValues={defaultValues} />
        </SearchBar>
        <Categorys>
          <InnerCategory>
            <div style={{ display: 'flex', maxHeight: '5vh', marginBottom: '5px', marginTop: '5px' }}>
              {state.strapi.categories?.map(({ id: itemID, name, icon = {}, slug }: any) => {
                return (
                  <CategoryContainer key={`category-banner-item-{${itemID}}`}>
                    <CategoryButton
                      onClick={handleClick(slug)}
                      className={activeCategory.includes(slug) ? 'active' : ''}
                    >
                      <div
                        style={{
                          flexDirection: 'column',
                        }}
                      >
                        <CategoryIcon>
                          <img src={icon?.url || DEFAULT_ICON} alt="icon" style={{ width: '20px' }} />
                        </CategoryIcon>
                        <CategoryTitle>{name}</CategoryTitle>
                      </div>
                    </CategoryButton>
                  </CategoryContainer>
                )
              })}
            </div>
          </InnerCategory>
        </Categorys>
      </ContainerSearchBar>
      {filterConfig && mobileMode && (
        <FilterRow>
          <FilterButton onClick={handleFilterClick}>
            <FilterIcon />
          </FilterButton>
          <ScrollTags>
            {getTagItems(filterConfig?.values || {}).map(({ property, value }) => (
              <Tag key={`${value}-tag`}>
                <span>{value || ''}</span>
                <CloseTag onClick={handleTagClick(property, value)}>
                  <CancelIcon />
                </CloseTag>
              </Tag>
            ))}
          </ScrollTags>
          {showFilter &&
            createPortal(
              <FilterBottomPanel>
                <CloseButton onClick={handleFilterClick}></CloseButton>
                <FilterColumn>
                  <Filter
                    mobileMode={false}
                    title={filterConfig.title}
                    fields={filterConfig.fields}
                    values={filterConfig.values}
                    onChangeByPropName={filterConfig.onChangeByPropName}
                  />
                </FilterColumn>
              </FilterBottomPanel>,
              document.body
            )}
        </FilterRow>
      )}
    </Container>
  )
}
