export const slugsToIds = (slugs = '', categories: any[]): any => {
  const categoryIds: string[] = []
  const subCategoryIds: string[] = []

  slugs.split('-').forEach((slug: string) => {
    const category = categories.find((c: any) => c.slug === slug)
    const idsSet = (category?.categorySet?.map((c: any) => c.id) || []).filter((id: string) => !!id)
    if (category) {
      if (category.searchType === 'SubCategory') {
        subCategoryIds.push(...idsSet)
      } else {
        categoryIds.push(...idsSet)
      }
    }
  })

  const query: any = {}

  if (categoryIds.length) {
    query.category = categoryIds.join('-')
  }

  if (subCategoryIds.length) {
    query.sub_category = subCategoryIds.join('-')
  }

  return query
}
